import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row q-mt-sm" }
const _hoisted_2 = { class: "row col-xs-12 items-center" }
const _hoisted_3 = { class: "row col-xs-12 items-center" }
const _hoisted_4 = { class: "row col-xs-12 items-center" }
const _hoisted_5 = { class: "row q-mt-lg" }
const _hoisted_6 = {
  key: 0,
  class: "row q-mt-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_documents = _resolveComponent("documents")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    visible: _ctx.showAnnouncementDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAnnouncementDialog) = $event)),
    title: _ctx.announcement?.title,
    translatableTitle: "",
    errors: [],
    "is-processing": _ctx.loading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_icon, {
            name: "group",
            class: "q-mr-xs"
          }),
          (!_ctx.announcement)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.announcement.commission?.name), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_icon, {
            name: "person",
            class: "q-mr-xs"
          }),
          (!_ctx.announcement)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.announcement.user.name), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_icon, {
            name: "event",
            class: "q-mr-xs"
          }),
          (!_ctx.announcement)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDatetime(_ctx.announcement.created_at)), 1)
              ], 64))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.announcement)
          ? (_openBlock(), _createBlock(_component_q_skeleton, {
              key: 0,
              class: "col-xs-12",
              height: "50px"
            }))
          : (_openBlock(), _createBlock(_component_translatable_content, {
              key: 1,
              text: _ctx.announcement.contents
            }, null, 8, ["text"]))
      ]),
      (
                _ctx.announcement &&
                _ctx.announcement.document_archive !== null &&
                _ctx.announcement.document_archive.documents.length > 0
            )
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_documents, {
              documentArchive: _ctx.announcement.document_archive
            }, null, 8, ["documentArchive"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "title", "is-processing"]))
}