import { RouteRecordRaw } from 'vue-router';
import SignIn from '@/views/SignIn.vue';
import AnnouncementOverview from '@/views/Announcement/AnnouncementOverview.vue';
import DocumentArchiveOverview from '@/views/DocumentArchive/DocumentArchiveOverview.vue';
import PhotoAlbumOverview from '@/views/PhotoAlbum/PhotoAlbumOverview.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ForumOverview from '@/views/Forum/ForumOverview.vue';
import ForumDetails from '@/views/Forum/ForumDetails.vue';
import UserOverview from '@/views/User/UserOverview.vue';
import InformationDocumentsOverview from '@/views/InformationDocuments/InformationDocumentsOverview.vue';
import CommissionOverview from '@/views/Commission/CommissionOverview.vue';
import VillaOverview from '@/views/Villa/VillaOverview.vue';
import AccountInformation from '@/views/AccountInformation.vue';

const routes: Array<RouteRecordRaw> = [
    { path: '/', redirect: { name: 'announcement.overview' } },
    {
        path: '/login',
        name: 'sign-in',
        component: SignIn,
        meta: {
            guest: true,
        },
    },
    {
        path: '/reset-password/:token/:email',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            guest: true,
        },
    },
    {
        path: '/announcements/:announcement?',
        name: 'announcement.overview',
        component: AnnouncementOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/document-archives/:documentarchive?',
        name: 'documentarchive.overview',
        component: DocumentArchiveOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/forum',
        name: 'forum.overview',
        component: ForumOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/forum/:post',
        name: 'forum.details',
        component: ForumDetails,
        meta: {
            auth: true,
        },
    },
    {
        path: '/photo-albums/:photoalbum?',
        name: 'photoalbum.overview',
        component: PhotoAlbumOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/commissions',
        name: 'commissions',
        component: CommissionOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/users/:user?',
        name: 'user.overview',
        component: UserOverview,
        meta: {
            auth: true,
            admin: true,
        },
    },
    {
        path: '/information-documents',
        name: 'information-documents.overview',
        component: InformationDocumentsOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/villas/:villa?',
        name: 'villa.overview',
        component: VillaOverview,
        meta: {
            auth: true,
        },
    },
    {
        path: '/account-information',
        name: 'account.information',
        component: AccountInformation,
        meta: {
            auth: true,
        },
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    },
];

export default routes;
