
import DocumentArchive from '@/api/models/DocumentArchive';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import DocumentArchiveDetails from '@/views/DocumentArchive/DocumentArchiveDetails.vue';
import DocumentArchiveCreate from '@/views/DocumentArchive/DocumentArchiveCreate.vue';
import DocumentArchiveUpdate from '@/views/DocumentArchive/DocumentArchiveUpdate.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'DocumentArchiveOverview',
    components: {
        OverviewPageTableLayout,
        DocumentArchiveUpdate,
        DocumentArchiveDetails,
        DocumentArchiveCreate,
        TranslatableContent,
    },
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();

        const loading = ref(true);
        const user = computed(() => store.getters.user);
        const isAdmin = computed(() => store.getters.isAdmin);
        const documentArchiveSlug: Ref<string | null> = ref(null);
        const documentArchiveUpdateSlug: Ref<string | null> = ref(null);
        const showDocumentArchiveDialog = ref(false);
        const showDocumentArchiveCreateDialog = ref(false);
        const showDocumentArchiveUpdateDialog = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const documentarchives: Ref<DocumentArchive[]> = ref([]);
        const loadDocumentArchives = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;

            documentarchives.value = [];

            const response = await DocumentArchive.all({
                search: searchFilter.value || undefined,
                pageNumber: props.pagination.page,
            });

            documentarchives.value = response.documentarchives;

            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loading.value = false;
        };

        const searchFilter = ref(null);

        const search = () => {
            loadDocumentArchives({ pagination: pagination.value });
        };

        const openDocumentArchiveDetailDialog = (slug: string) => {
            documentArchiveSlug.value = slug;
            showDocumentArchiveDialog.value = true;
        };

        watch(
            () => route.params.documentarchive,
            () => {
                if (route.params.documentarchive && route.params.documentarchive !== '') {
                    openDocumentArchiveDetailDialog(route.params.documentarchive as string);
                }
            }
        );

        watch(
            () => showDocumentArchiveDialog.value,
            () => {
                if (!showDocumentArchiveDialog.value) {
                    router.push({ name: 'documentarchive.overview' });
                }
            }
        );

        onBeforeMount(async () => {
            await loadDocumentArchives({ pagination: pagination.value });
            if (route.params.documentarchive && route.params.documentarchive !== '') {
                openDocumentArchiveDetailDialog(route.params.documentarchive as string);
            }
        });

        return {
            t,
            user,
            isAdmin,
            documentArchiveSlug,
            documentArchiveUpdateSlug,
            showDocumentArchiveDialog,
            showDocumentArchiveCreateDialog,
            showDocumentArchiveUpdateDialog,
            documentarchives,
            loadDocumentArchives,
            pagination,
            loading,
            pagesNumber,
            formatDate,
            search,
            searchFilter,
            openDocumentArchiveDetailDialog,
        };
    },
});
