
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    Ref,
    ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import Villa from '@/api/models/Villa';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import { QPaginationMeta } from '@/util/pagination';
import VillaDetail from './VillaDetail.vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: { OverviewPageTableLayout, VillaDetail },
    name: 'VillaOverview',
    setup() {
        const { t } = useI18n();
        const loading = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'number',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 147,
        });

        const villas: Ref<Villa[]> = ref([]);

        const searchQuery = ref('');
        const searchedVillas: ComputedRef<Villa[] | undefined> = computed(() => {
            const regex = new RegExp(searchQuery.value?.trim(), 'i');
            const villaFilter = (villa: Villa) => {
                return (
                    regex.test(
                        villa.users
                            ?.map((user) => user.name)
                            .join(' ')
                            .toString() ?? ''
                    ) || villa.number?.toString() === searchQuery.value
                );
            };

            if (searchQuery.value && searchQuery.value !== '') {
                return villas.value.filter(villaFilter);
            }
            return villas.value;
        });

        const showDetailDialog = ref(false);
        const villaNumber = ref('');
        const openDialog = (number: string) => {
            villaNumber.value = number;
            showDetailDialog.value = true;
        };

        const route = useRoute();
        watch(
            () => route.params.villa,
            () => {
                if (route.params.villa && route.params.villa !== '') {
                    openDialog(route.params.villa as string);
                }
            }
        );

        const router = useRouter();
        watch(
            () => showDetailDialog.value,
            () => {
                if (!showDetailDialog.value) {
                    router.push({ name: 'villa.overview' });
                }
            }
        );

        onBeforeMount(async () => {
            if (route.params.villa && route.params.villa !== '') {
                openDialog(route.params.villa as string);
            }
            loading.value = true;
            villas.value = await Villa.all();
            loading.value = false;
        });

        return {
            t,
            villas,
            searchQuery,
            loading,
            pagination,
            searchedVillas,
            showDetailDialog,
            villaNumber,
        };
    },
});
