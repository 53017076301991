{
    "Authenticating": "Verifiëren",
    "Delete": "Verwijderen",
    "Save": "Opslaan",
    "Are you sure you want to delete this?": "Weet u zeker dat u dit wilt verwijderen?",
    "Cancel": "Annuleren",
    "Confirm delete": "Verwijderen bevestigen",
    "Password forgotten": "Wachtwoord vergeten",
    "Reset password": "Wachtwoord opnieuw instellen",
    "Email": "E-mail",
    "Password reset request succesful. Check your email for the reset link.": "Verzoek om het opnieuw instellen van het wachtwoord is geslaagd. Controleer uw e-mail op de resetlink.",
    "Hide": "Verbergen",
    "Announcements": "Mededelingen",
    "Documents": "Documenten",
    "Forum": "Forum",
    "Photos": "Foto's",
    "Commissions": "Commissies",
    "Users": "Gebruikers",
    "Search": "Zoeken",
    "No data was found": "Er zijn geen gegevens gevonden",
    "Commission": "Commissie",
    "Clear": "Leegmaken",
    "Close": "Sluiten",
    "User": "Gebruiker",
    "Villas": "Villa's",
    "Villa": "Villa",
    "Owner of house(s)": "Eigenaar van huis/huizen",
    "Owner": "Eigenaar",
    "Owners": "Eigenaren",
    "Home address": "Thuisadres",
    "Telephone number": "Telefoonnummer",
    "Mobile phone number": "Mobiel telefoonnummer",
    "Hobbies": "Hobby's",
    "Date of birth": "Geboortedatum",
    "Account information": "Accountgegevens",
    "New avatar": "Nieuwe avatar",
    "Change password": "Wachtwoord wijzigen",
    "Current password": "Huidig wachtwoord",
    "New password": "Nieuw wachtwoord",
    "Confirm password": "Wachtwoord bevestigen",
    "Change": "Veranderen",
    "Personal information": "Persoonlijke informatie",
    "Phone number": "Telefoonnummer",
    "Receive forum post notifications": "Meldingen over forumberichten ontvangen",
    "Street": "Straat",
    "Number": "Nummer",
    "Postal code": "Postcode",
    "City": "Stad",
    "Create new announcement": "Nieuwe mededeling maken",
    "Title": "Titel",
    "Contents": "Inhoud",
    "Upload documents (optional)": "Documenten uploaden (optioneel)",
    "Announcement": "Mededeling",
    "Create announcement": "Mededeling maken",
    "Update announcement": "Mededeling bijwerken",
    "Upload additional documents (optional)": "Extra documenten uploaden (optioneel)",
    "Only members of commissions can update their own announcements.": "Alleen commissieleden kunnen hun eigen mededelingen bijwerken.",
    "Edit a commission": "Een commissie bewerken",
    "Create new commission": "Nieuwe commissie maken",
    "Name": "Naam",
    "Add member": "Lid toevoegen",
    "Create commission": "Commissie maken",
    "Edit": "Bewerken",
    "Create new document archive": "Nieuw documentarchief maken",
    "Meeting date": "Vergaderdatum",
    "Upload date": "Uploaddatum",
    "Document archives": "Documenten archieven",
    "Create archive": "Archief maken",
    "Update document archive": "Documentarchief bijwerken",
    "Create a new forum post": "Een nieuw forumbericht maken",
    "Forum message": "Forumbericht",
    "Delete post": "Bericht verwijderen",
    "Type your comment here": "Typ hier uw reactie",
    "Add comment": "Commentaar toevoegen",
    "Create post": "Bericht maken",
    "Sort by": "Sorteren op",
    "Date (latest first)": "Datum (laatste eerst)",
    "Date (oldest first)": "Datum (oudste eerst)",
    "Views": "Weergaven",
    "Comments": "Reacties",
    "Create a new photo album": "Een nieuw fotoalbum maken",
    "Description": "Beschrijving",
    "Photo albums": "Fotoalbums",
    "Create album": "Album maken",
    "Ownersportal": "Eigenarenportaal",
    "Password": "Wachtwoord",
    "Password confirmation": "Wachtwoordbevestiging",
    "Reset": "Herstellen",
    "Sign in": "Aanmelden",
    "These credentials are wrong": "Deze inloggegevens zijn onjuist",
    "Admin": "Admin",
    "Same address and villas as (optional)": "Hetzelfde adres en villa's als (optioneel)",
    "Create user": "Gebruiker maken",
    "No owners yet": "Nog geen eigenaren",
    "Logout": "Uitloggen",
    "validation": {
        "required": "{fieldName} is verplicht",
        "email": "Geef alsjeblieft een geldig e-mailadres",
        "maxLength": "{fieldName} mag niet langer zijn dan {length} karakters",
        "atLeastOne": "Selecteer minimaal één {name}",
        "strongPassword": "Wachtwoorden moeten minimaal 8 tekens, een letter, een hoofdletter en een cijfer bevatten."
    },
    "commission": "commissie",
    "document": "document",
    "photo": "foto",
    "Please fill in both translations.": "Vul alsjeblieft beide vertalingen in",
    "Password confirmation is not equal to new password.": "Wachtwoord bevestiging moet hetzelfde zijn als nieuw wachtwoord.",
    "Please choose an image first": "Kies eerst een nieuwe foto",
    "Fill in a title for at least one language.": "Vul tenminste voor één taal een titel in.",
    "Fill in a comment for for at least one language.": "Vul tenminste voor één taal commentaar in.",
    "Board": "Bestuur",
    "Information": "Informatie",
    "Information documents": "Informatie documenten",
    "Upload document": "Upload document",
    "Document": "Document",
    "Upload new document": "Upload nieuw document"
}
