
import User from '@/api/models/User';
import { formatDate } from '@/util/formatters';
import { defineComponent, PropType } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'UserDisplayCard',
    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
    },
    setup() {
        const { t } = useI18n();

        return { t, formatDate };
    },
});
