
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
    name: 'Errors',
    setup(props) {
        const reactiveErrors = ref(props.errors);

        watch(
            () => props.errors,
            (e) => {
                reactiveErrors.value = e;
            }
        );

        return {
            reactiveErrors,
        };
    },
    props: {
        errors: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
});
