import User from '@/api/models/User';
import { MutationTree } from 'vuex';
import { State } from './state';
import i18n from '@/i18n';

export enum MutationTypes {
    setUser = 'setUser',
    setIsAuthenticating = 'setIsAuthenticating',
    setUsers = 'setUsers',
    setDisplayLanguage = 'setDisplayLanguage',
    toggleMenu = 'toggleMenu',
    setMenu = 'setMenu',
}

interface Mutations extends MutationTree<State> {
    [MutationTypes.setUser]: (state: State, payload: User) => void;
    [MutationTypes.setIsAuthenticating]: (state: State, payload: boolean) => void;
    [MutationTypes.setUsers]: (state: State, payload: User[]) => void;
    [MutationTypes.setDisplayLanguage]: (state: State, payload: string) => void;
    [MutationTypes.toggleMenu]: (state: State) => void;
    [MutationTypes.setMenu]: (state: State, payload: boolean) => void;
}

const mutations: Mutations = {
    [MutationTypes.setUser]: (state, payload) => {
        state.user = payload;

        if (payload !== null) {
            i18n.global.locale.value = state.user.language || 'nl';
            state.displayLanguage = state.user.language || 'nl';
        }
    },

    [MutationTypes.setIsAuthenticating]: (state, payload) => {
        state.isAuthenticating = payload;
    },

    [MutationTypes.setUsers]: (state, payload) => {
        state.users = payload;
    },

    [MutationTypes.setDisplayLanguage]: (state, payload) => {
        i18n.global.locale.value = payload || 'nl';
        state.displayLanguage = payload || 'nl';
    },

    [MutationTypes.toggleMenu]: (state) => {
        state.isMenuOpen = !state.isMenuOpen;
    },
    [MutationTypes.setMenu]: (state, payload) => {
        state.isMenuOpen = payload;
    },
};

export default mutations;
