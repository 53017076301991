import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae2746d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "col-grow text-h6"
}
const _hoisted_2 = {
  key: 2,
  class: "self-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_errors = _resolveComponent("errors")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_delete_confirm_dialog = _resolveComponent("delete-confirm-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.show,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
      persistent: _ctx.withDelete || _ctx.withSave,
      maximized: this.$q.screen.width < 492
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: _normalizeClass({
                'crud-dialog-card-large': _ctx.large,
                'crud-dialog-card-medium': _ctx.medium,
                'crud-dialog-card-small': _ctx.small,
            }),
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "row reverse-wrap justify-end" }, {
              default: _withCtx(() => [
                (!_ctx.title)
                  ? (_openBlock(), _createBlock(_component_q_skeleton, {
                      key: 0,
                      class: "col-6",
                      type: "rect"
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_ctx.translatableTitle)
                        ? (_openBlock(), _createBlock(_component_translatable_content, {
                            key: 0,
                            text: _ctx.title
                          }, null, 8, ["text"]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ], 64))
                    ])),
                (_ctx.languageSelect)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_language_selector, {
                        modelValue: _ctx.languageObject,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.languageObject) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                _withDirectives(_createVNode(_component_q_btn, {
                  class: "self-end",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                  icon: "close",
                  flat: "",
                  round: "",
                  dense: ""
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            }),
            (_ctx.errors.length > 0)
              ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_errors, { errors: _ctx.errors }, null, 8, ["errors"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            }),
            (_ctx.withDelete || _ctx.withSave)
              ? (_openBlock(), _createBlock(_component_q_card_actions, {
                  key: 1,
                  class: "row justify-end q-gutter-sm q-pa-md"
                }, {
                  default: _withCtx(() => [
                    (_ctx.withDelete)
                      ? (_openBlock(), _createBlock(_component_q_space, { key: 0 }))
                      : _createCommentVNode("", true),
                    _renderSlot(_ctx.$slots, "additional-actions", {}, undefined, true),
                    (_ctx.withSave)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          label: _ctx.t('Save'),
                          color: "secondary",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('save'))),
                          class: _normalizeClass({
                        'full-width': this.$q.screen.width < 1024,
                        'order-last': this.$q.screen.width >= 1024,
                    })
                        }, null, 8, ["label", "class"]))
                      : _createCommentVNode("", true),
                    (_ctx.withDelete)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 2,
                          flat: "",
                          label: _ctx.t('Delete'),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteConfirm ? (_ctx.deleteConfirmDialog = true) : _ctx.$emit('delete'))),
                          "text-color": "negative",
                          class: _normalizeClass({
                        'full-width': this.$q.screen.width < 1024,
                        'order-first': this.$q.screen.width >= 1024,
                    })
                        }, null, 8, ["label", "class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_inner_loading, { showing: _ctx.isProcessing }, {
              default: _withCtx(() => [
                _createVNode(_component_q_spinner_gears, {
                  size: "50px",
                  color: "primary"
                })
              ]),
              _: 1
            }, 8, ["showing"])
          ]),
          _: 3
        }, 8, ["class"])
      ]),
      _: 3
    }, 8, ["modelValue", "persistent", "maximized"]),
    _createVNode(_component_delete_confirm_dialog, {
      visible: _ctx.deleteConfirmDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.deleteConfirmDialog) = $event)),
      onDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('delete')))
    }, null, 8, ["visible"])
  ], 64))
}