
import { defineComponent, PropType, WritableComputedRef, computed, ref, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import TranslatableText from '@/api/models/TranslatableText';
import { required, maxLength } from '@/validation/validation';
import QInput from 'quasar/src/components/input/QInput.js';;

export default defineComponent({
    name: 'TranslatableInput',
    emits: ['update:modelValue', 'update:isValid'],
    props: {
        modelValue: {
            type: Object as PropType<TranslatableText>,
            required: true,
        },
        language: {
            type: String,
        },
        title: {
            type: String,
        },
        type: {
            type: String,
        },
        isValid: {
            type: Boolean,
            required: false,
        },
        optional: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const translatableText: WritableComputedRef<TranslatableText> = computed({
            get: () => props.modelValue,
            set: (v: TranslatableText) => {
                emit('update:modelValue', v);
            },
        });

        const inputRefNl = ref<QInput | null>(null);
        const inputRefFr = ref<QInput | null>(null);
        const validateForm = async () => {
            await nextTick();
            let formIsValid = false;

            if (inputRefNl.value && inputRefFr.value) {
                formIsValid =
                    (inputRefNl.value.validate() as boolean) &&
                    (inputRefFr.value.validate() as boolean);
            }
            emit('update:isValid', formIsValid);
        };

        return { t, translatableText, required, maxLength, inputRefNl, inputRefFr, validateForm };
    },
});
