import api, { PaginatedApiMeta } from '../core/api';
import { FileData } from './File';
import TranslatableText, { TranslatableTextData } from './TranslatableText';

export interface PhotoAlbumData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    description?: TranslatableTextData;
    photo?: FileData;
    photos?: FileData[];
    created_by?: string;
    created_at?: string;
}

export default class PhotoAlbum implements PhotoAlbumData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    description?: TranslatableTextData;
    photo?: FileData;
    photos?: FileData[];
    created_by?: string;
    created_at?: string;

    constructor(data: PhotoAlbumData) {
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = new TranslatableText(data.title ?? {});
        this.description = new TranslatableText(data.description ?? {});
        this.photo = data.photo;
        this.photos = data.photos;
        this.created_by = data.created_by;
        this.created_at = data.created_at;
    }

    static async all(
        config: {
            search?: string;
            numberPerPage?: number;
            pageNumber?: number;
        } = {}
    ): Promise<{ photoAlbums: PhotoAlbum[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<PhotoAlbumData[]>('/photo-albums', {
            search: config.search || null,
            number: config.numberPerPage,
            page: config.pageNumber,
        });

        return {
            photoAlbums: response.data.map((fp) => new PhotoAlbum(fp)),
            meta: response.meta,
        };
    }

    static async get(slug: string): Promise<PhotoAlbum> {
        const response = await api.get<PhotoAlbum>(`/photo-albums/${slug}`);
        return new PhotoAlbum(response.data);
    }

    async save(photos: File[]): Promise<PhotoAlbum> {
        const fd = new FormData();

        fd.append('title', TranslatableText.getJson(this.title));
        fd.append('description', TranslatableText.getJson(this.description));

        for (const photo of photos) {
            fd.append('photos[]', photo);
        }

        const response = await api.post<PhotoAlbum>(
            '/photo-albums',
            fd,
            {},
            { 'Content-Type': 'multipart/form-data' }
        );

        return new PhotoAlbum(response.data);
    }

    hasTitleForAtLeastOneLanguage(): boolean {
        return this.title?.nl !== '' || this.title?.fr !== '';
    }
}
