
import PhotoAlbum from '@/api/models/PhotoAlbum';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import {
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    computed,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDatetime } from '@/util/formatters';

export default defineComponent({
    name: 'PhotoAlbumsDetails',
    components: { CrudDialog, TranslatableContent },
    props: {
        /**
         * If the dialog is opened.
         */
        visible: {
            default: false,
            type: Boolean,
        },
        /**
         * Slug of the photoalbum.
         */
        photoAlbumSlug: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showPhotoAlbumDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const photoalbum: Ref<PhotoAlbum | null> = ref(null);
        const photoSlide: Ref<number> = ref(1);

        const loadPhotoAlbum = async () => {
            if (props.photoAlbumSlug) {
                photoalbum.value = null;
                photoalbum.value = await PhotoAlbum.get(props.photoAlbumSlug as string);
            }
        };

        onBeforeMount(async () => await loadPhotoAlbum());

        watch(
            () => props.photoAlbumSlug,
            async () => await loadPhotoAlbum()
        );

        return {
            t,
            showPhotoAlbumDialog,
            photoalbum,
            photoSlide,
            formatDatetime,
        };
    },
});
