
import { ApiValidationError } from '@/api/core/api';
import Commission, { Member } from '@/api/models/Commission';
import User from '@/api/models/User';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import UserSelector from '@/components/Selectors/UserSelector.vue';
import {
    computed,
    defineComponent,
    PropType,
    Ref,
    ref,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { required, maxLength } from '@/validation/validation';
import QInput from 'quasar/src/components/input/QInput.js';;

export default defineComponent({
    components: { CrudDialog, UserSelector },
    name: 'CommissionCreate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        commission: {
            type: Object as PropType<Commission | null>,
            required: false,
            default: null,
        },
    },
    emits: ['update:modelValue', 'reloadCommissions'],
    setup(props, { emit }) {
        const { t } = useI18n();

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        const isBoard = ref(false);
        const newCommissionName = ref('');
        const members: Ref<Member[]> = ref([{ user: null, title: null }]);

        const addMember = () => members.value.push({ user: null, title: null });
        const deleteMemberRow = (idx: number) => {
            if (members.value.length <= 1) {
                return;
            }
            members.value.splice(idx, 1);
        };

        const disableRemove = ref(false);
        watch(members.value, () => {
            if (members.value.length == 1) disableRemove.value = true;
            else disableRemove.value = false;
        });

        const commissionNameInput = ref<QInput | null>(null);

        const isProcessing = ref(false);
        const errors: Ref<string[]> = ref([]);
        const saveCommission = async () => {
            if (commissionNameInput.value?.validate()) {
                isProcessing.value = true;
                try {
                    if (props.commission) {
                        await props.commission.save(members.value);
                    } else {
                        await Commission.create(
                            newCommissionName.value,
                            members.value,
                            isBoard.value
                        );
                    }

                    emit('reloadCommissions');
                    visible.value = false;

                    emptyDialogFields();
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            }
        };

        const emptyDialogFields = () => {
            members.value = [{ user: null, title: null }];
            newCommissionName.value = '';
            isBoard.value = false;
            errors.value = [];
        };

        watch(
            () => props.commission,
            () => {
                if (props.commission) {
                    newCommissionName.value = props.commission.name as string;
                    isBoard.value = props.commission.is_board as boolean;
                    members.value = [];
                    for (let user of props.commission.users as User[]) {
                        members.value.push({ user, title: user.title as string });
                    }
                } else {
                    emptyDialogFields();
                }
            }
        );

        const deleteCommission = async () => {
            isProcessing.value = true;
            await props?.commission?.delete();

            emit('reloadCommissions');
            visible.value = false;
            isProcessing.value = false;
        };

        return {
            t,
            visible,
            errors,
            saveCommission,
            newCommissionName,
            members,
            addMember,
            deleteMemberRow,
            disableRemove,
            isBoard,
            isProcessing,
            deleteCommission,
            required,
            maxLength,
            commissionNameInput,
        };
    },
});
