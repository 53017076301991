
import { ApiAuthenticationError, ApiValidationError } from '@/api/core/api';
import Errors from '@/components/Notifications/Errors.vue';
import { defineComponent, ref, Ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { MutationTypes } from '@/store/mutations';
import User from '@/api/models/User';
import PasswordForgottenDialog from '@/components/Interaction/PasswordForgottenDialog.vue';
import { required, email } from '@/validation/validation';
import QInput from 'quasar/src/components/input/QInput.js';;

export default defineComponent({
    components: { Errors, PasswordForgottenDialog },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const router = useRouter();

        const emailInputRef = ref<QInput | null>(null);
        const passwordRef = ref<QInput | null>(null);

        const setLang = (lang: string) => {
            i18n.locale.value = lang;
            emailInputRef.value?.validate();
            passwordRef.value?.validate();
        };

        const emailInput: Ref<string> = ref('');
        const password: Ref<string> = ref('');
        const errors: Ref<string[]> = ref([]);
        const isLoading: Ref<boolean> = ref(false);

        const signIn = async () => {
            if (isLoading.value) return;

            isLoading.value = true;
            errors.value = [];

            try {
                const user = await User.signIn(emailInput.value, password.value);
                store.commit(MutationTypes.setUser, user);
                i18n.locale.value = user.language || i18n.locale.value;
                router.replace({ name: 'announcement.overview' });
            } catch (e) {
                if (e instanceof ApiAuthenticationError) {
                    errors.value = [i18n.t('These credentials are wrong')];
                } else if (e instanceof ApiValidationError) {
                    errors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(() => {
            if (store.getters.isAuthenticated) {
                router.replace({ name: 'announcement.overview' });
            }
        });

        return {
            t: i18n.t,
            setLang,
            emailInput,
            password,
            signIn,
            errors,
            isLoading,
            required,
            email,
            emailInputRef,
            passwordRef,
        };
    },
});
