import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row q-mt-sm" }
const _hoisted_2 = { class: "row col-xs-12 items-center" }
const _hoisted_3 = { class: "row col-xs-12 items-center" }
const _hoisted_4 = { class: "row q-mt-lg" }
const _hoisted_5 = {
  class: "row q-mt-lg",
  style: {"height":"500px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_q_carousel = _resolveComponent("q-carousel")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    visible: _ctx.showPhotoAlbumDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPhotoAlbumDialog) = $event)),
    title: _ctx.photoalbum?.title,
    translatableTitle: "",
    errors: [],
    "is-processing": false,
    "with-delete": false,
    "with-save": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_icon, {
            name: "person",
            class: "q-mr-xs"
          }),
          (!_ctx.photoalbum)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.photoalbum.created_by), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_icon, {
            name: "event",
            class: "q-mr-xs"
          }),
          (!_ctx.photoalbum)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDatetime(_ctx.photoalbum.created_at)), 1)
              ], 64))
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.photoalbum)
          ? (_openBlock(), _createBlock(_component_q_skeleton, {
              key: 0,
              class: "col-xs-12",
              height: "50px"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.photoalbum.description)
                ? (_openBlock(), _createBlock(_component_translatable_content, {
                    key: 0,
                    text: _ctx.photoalbum.description
                  }, null, 8, ["text"]))
                : _createCommentVNode("", true)
            ], 64))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.photoalbum)
          ? (_openBlock(), _createBlock(_component_q_skeleton, {
              key: 0,
              class: "col-xs-12",
              "full-height": ""
            }))
          : (_openBlock(), _createBlock(_component_q_carousel, {
              key: 1,
              class: "col-xs-12",
              swipeable: "",
              animated: "",
              modelValue: _ctx.photoSlide,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.photoSlide) = $event)),
              thumbnails: "",
              infinite: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photoalbum.photos, (photo, i) => {
                  return (_openBlock(), _createBlock(_component_q_carousel_slide, {
                    style: {"background-size":"contain","background-repeat":"no-repeat","background-color":"white"},
                    key: photo.uuid,
                    name: i + 1,
                    "img-src": photo.url
                  }, null, 8, ["name", "img-src"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]))
      ])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}