import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "caption q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_menu = _resolveComponent("app-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh lpR fFf" }, {
    default: _withCtx(() => [
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createBlock(_component_app_menu, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          (_ctx.isAuthenticated)
            ? (_openBlock(), _createBlock(_component_q_page, {
                key: 0,
                padding: this.$q.screen.width > 1024,
                class: "page-background column justify-center items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card, {
                    class: "col fit page-card",
                    square: this.$q.screen.width <= 1024
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, {
                        class: _normalizeClass({ 'q-pa-lg': this.$q.screen.width > 480 })
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_view)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }, 8, ["square"])
                ]),
                _: 1
              }, 8, ["padding"]))
            : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        ]),
        _: 1
      }),
      _createVNode(_component_spinner, {
        modelValue: _ctx.isAuthenticating,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAuthenticating) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('Authenticating')) + "...", 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}