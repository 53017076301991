import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xs-12 row text-body1" }
const _hoisted_3 = { class: "row text-caption items-center justify-start" }
const _hoisted_4 = {
  key: 0,
  class: "row items-center col-xs-12"
}
const _hoisted_5 = { class: "row items-center col-xs-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_document_archive_details = _resolveComponent("document-archive-details")!
  const _component_document_archive_update = _resolveComponent("document-archive-update")!
  const _component_document_archive_create = _resolveComponent("document-archive-create")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_document_archive_details, {
      documentArchiveSlug: _ctx.documentArchiveSlug,
      "onUpdate:documentArchiveSlug": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentArchiveSlug) = $event)),
      visible: _ctx.showDocumentArchiveDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDocumentArchiveDialog) = $event))
    }, null, 8, ["documentArchiveSlug", "visible"]),
    _createVNode(_component_document_archive_update, {
      modelValue: _ctx.showDocumentArchiveUpdateDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDocumentArchiveUpdateDialog) = $event)),
      documentArchiveSlug: _ctx.documentArchiveUpdateSlug,
      onReload: _cache[3] || (_cache[3] = ($event: any) => {
            _ctx.loadDocumentArchives({ pagination: _ctx.pagination });
            _ctx.documentArchiveUpdateSlug = null;
        })
    }, null, 8, ["modelValue", "documentArchiveSlug"]),
    _createVNode(_component_document_archive_create, {
      modelValue: _ctx.showDocumentArchiveCreateDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDocumentArchiveCreateDialog) = $event)),
      onCreated: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadDocumentArchives({ pagination: _ctx.pagination })))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.documentarchives,
      "onUpdate:objects": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.documentarchives) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      "onUpdate:loading": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.loading) = $event)),
      editableRows: "",
      title: _ctx.t('Document archives'),
      createTitle: _ctx.t('Create archive'),
      onReload: _cache[10] || (_cache[10] = ($event: any) => (_ctx.loadDocumentArchives({ pagination: _ctx.pagination }))),
      hideCreateButton: !(_ctx.user?.is_commission_member || _ctx.user?.is_admin),
      onCreate: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showDocumentArchiveCreateDialog = !_ctx.showDocumentArchiveCreateDialog)),
      onDetail: _cache[12] || (_cache[12] = 
            ({ slug }) =>
                _ctx.$router.push({
                    name: 'documentarchive.overview',
                    params: { documentarchive: slug },
                })
        ),
      onUpdate: _cache[13] || (_cache[13] = 
            ({ slug }) => {
                _ctx.documentArchiveUpdateSlug = slug;
                _ctx.showDocumentArchiveUpdateDialog = true;
            }
        )
    }, {
      row: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_translatable_content, {
              text: props.object.title,
              truncate: ""
            }, null, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (props.object.commission)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_q_icon, {
                    name: "group",
                    class: "q-mr-xs"
                  }),
                  _createTextVNode(" " + _toDisplayString(props.object.commission.name), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "q-mr-xs"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(props.object.created_at)), 1)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle", "hideCreateButton"])
  ], 64))
}