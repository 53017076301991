import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }
const _hoisted_2 = { class: "q-pt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_user_selector = _resolveComponent("user-selector")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    medium: "",
    title: _ctx.commission ? _ctx.t('Edit a commission') : _ctx.t('Create new commission'),
    errors: _ctx.errors,
    "is-processing": _ctx.isProcessing,
    visible: _ctx.visible,
    "with-save": "",
    "with-delete": _ctx.commission,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onSave: _ctx.saveCommission,
    onDelete: _ctx.deleteCommission
  }, {
    "additional-actions": _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "primary",
        label: _ctx.t('Add member'),
        onClick: _ctx.addMember,
        class: _normalizeClass({ 'full-width': this.$q.screen.width < 1024 })
      }, null, 8, ["label", "onClick", "class"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_input, {
          modelValue: _ctx.newCommissionName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCommissionName) = $event)),
          label: _ctx.t('Name'),
          rules: [_ctx.required('Name'), _ctx.maxLength('Name', 255)],
          ref: "commissionNameInput",
          type: "text",
          class: "col-xs-12 col-sm-6"
        }, null, 8, ["modelValue", "label", "rules"]),
        _createVNode(_component_q_toggle, {
          modelValue: _ctx.isBoard,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isBoard) = $event)),
          label: _ctx.t('Board'),
          class: "col-xs-12 offset-sm-4 col-sm-2"
        }, null, 8, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "row q-col-gutter-md"
          }, [
            _createVNode(_component_user_selector, {
              user: member.user,
              "onUpdate:user": ($event: any) => ((member.user) = $event),
              class: "col-xs-12 col-sm-6"
            }, null, 8, ["user", "onUpdate:user"]),
            _createVNode(_component_q_input, {
              modelValue: member.title,
              "onUpdate:modelValue": ($event: any) => ((member.title) = $event),
              label: `${_ctx.t('Title')} (${_ctx.t('optional')})`,
              ref: _ctx.setMemberTitleRefs,
              class: "col-xs-12 col-sm-6"
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  icon: "delete",
                  round: "",
                  flat: "",
                  onClick: ($event: any) => (_ctx.deleteMemberRow(idx))
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "label"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "errors", "is-processing", "visible", "with-delete", "onSave", "onDelete"]))
}