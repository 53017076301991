
import InformationDocument from '@/api/models/InformationDocument';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import InformationDocumentCreate from '@/views/InformationDocuments/InformationDocumentCreate.vue';
import DeleteConfirmDialog from '@/components/Interaction/DeleteConfirmDialog.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import { computed, ComputedRef, defineComponent, onBeforeMount, ref, Ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'InformationDocumentsOverview',
    components: {
        OverviewPageTableLayout,
        InformationDocumentCreate,
        TranslatableContent,
        DeleteConfirmDialog,
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        const loading = ref(true);
        const user = computed(() => store.getters.user);
        const isAdmin = computed(() => store.getters.isAdmin);
        const showInformationDocumentCreateDialog = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const informationDocuments: Ref<InformationDocument[]> = ref([]);
        const informationDocumentToDelete: Ref<InformationDocument | null> = ref(null);

        const deleteInformationDocument = async () => {
            if (informationDocumentToDelete.value != null) {
                await informationDocumentToDelete.value.delete();
                await loadInformationDocuments({ pagination: pagination.value });
            }
        };

        const loadInformationDocuments = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;
            informationDocuments.value = [];
            const response = await InformationDocument.all({
                search: searchFilter.value || undefined,
                pageNumber: props.pagination.page,
            });
            informationDocuments.value = response.informationdocuments;
            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;
            loading.value = false;
        };

        const searchFilter = ref(null);

        const search = () => {
            loadInformationDocuments({ pagination: pagination.value });
        };

        onBeforeMount(async () => {
            await loadInformationDocuments({ pagination: pagination.value });
        });

        const downloadDocument = (url: string) => {
            window.open(url, '_blank');
        };

        return {
            t,
            user,
            isAdmin,
            showInformationDocumentCreateDialog,
            informationDocuments,
            loadInformationDocuments,
            pagination,
            loading,
            pagesNumber,
            formatDate,
            search,
            searchFilter,
            downloadDocument,
            informationDocumentToDelete,
            deleteInformationDocument,
        };
    },
});
