import api, { PaginatedApiMeta } from '../core/api';
import Commission, { CommissionData } from './Commission';
import { DocumentArchiveData } from './DocumentArchive';
import TranslatableText, { TranslatableTextData } from './TranslatableText';
import { UserData } from './User';

export interface AnnouncementData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    contents?: TranslatableTextData;
    commission?: CommissionData;
    created_at?: string;
    user?: UserData;
    document_archive?: DocumentArchiveData;
}

export default class Announcement implements AnnouncementData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    contents?: TranslatableTextData;
    commission?: CommissionData;
    created_at?: string;
    user?: UserData;
    document_archive?: DocumentArchiveData;

    constructor(data: AnnouncementData) {
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = new TranslatableText(data.title ?? {});
        this.contents = new TranslatableText(data.contents ?? {});
        this.commission = data.commission;
        this.created_at = data.created_at;
        this.user = data.user;
        this.document_archive = data.document_archive;
    }

    static async all(
        config: {
            search?: string;
            numberPerPage?: number;
            pageNumber?: number;
            commissions?: Commission[] | null;
        } = {}
    ): Promise<{ announcements: Announcement[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<AnnouncementData[]>('/announcements', {
            search: config.search || null,
            commissions: config.commissions?.map((commission) => commission.uuid),
            number: config.numberPerPage,
            page: config.pageNumber,
        });

        return {
            announcements: response.data.map((announcement) => new Announcement(announcement)),
            meta: response.meta,
        };
    }

    static async get(slug: string): Promise<Announcement> {
        const response = await api.get<Announcement>(`/announcements/${slug}`);
        return new Announcement(response.data);
    }

    async save(documents: File[]): Promise<Announcement> {
        const fd = new FormData();

        fd.append('title', TranslatableText.getJson(this.title));
        fd.append('contents', TranslatableText.getJson(this.contents));
        fd.append('commission', this.commission?.uuid as string);
        for (const document of documents) {
            fd.append('documents[]', document);
        }

        const url = this.uuid == null ? '/announcements' : `/announcements/${this.getSlug()}`;
        const response = await api.post<Announcement>(
            url,
            fd,
            {},
            { 'Content-Type': 'multipart/form-data' }
        );
        return new Announcement(response.data);
    }

    async delete(): Promise<void> {
        await api.delete(`/announcements/${this.getSlug()}`);
    }

    getSlug(): string | undefined {
        return this.slug?.nl || this.slug?.fr;
    }
}
