
import PhotoAlbum from '@/api/models/PhotoAlbum';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import PhotoAlbumDetails from '@/views/PhotoAlbum/PhotoAlbumDetails.vue';
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import { useRoute, useRouter } from 'vue-router';
import PhotoAlbumCreate from './PhotoAlbumCreate.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';

export default defineComponent({
    name: 'PhotoAlbumOverview',
    components: {
        OverviewPageTableLayout,
        TranslatableContent,
        PhotoAlbumDetails,
        PhotoAlbumCreate,
    },
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const loading = ref(true);
        const photoAlbumSlug: Ref<string | null> = ref(null);
        const showPhotoAlbumDialog = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            // This should be a multiple of 3 and 2
            rowsPerPage: 12,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const photoAlbums: Ref<PhotoAlbum[]> = ref([]);
        const loadPhotoAlbums = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;

            photoAlbums.value = [];

            const response = await PhotoAlbum.all({
                search: searchFilter.value || undefined,
                numberPerPage: props.pagination.rowsPerPage,
                pageNumber: props.pagination.page,
            });

            photoAlbums.value = response.photoAlbums;

            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loading.value = false;
        };

        const searchFilter = ref(null);

        const search = () => {
            loadPhotoAlbums({ pagination: pagination.value });
        };

        const openDialog = (slug: string) => {
            photoAlbumSlug.value = slug;
            showPhotoAlbumDialog.value = true;
        };

        const showCreatePhotoAlbumDialog = ref(false);

        watch(
            () => route.params.photoalbum,
            () => {
                if (route.params.photoalbum && route.params.photoalbum !== '') {
                    openDialog(route.params.photoalbum as string);
                }
            }
        );

        watch(
            () => showPhotoAlbumDialog.value,
            () => {
                if (!showPhotoAlbumDialog.value) {
                    router.push({ name: 'photoalbum.overview' });
                }
            }
        );

        onBeforeMount(async () => {
            await loadPhotoAlbums({ pagination: pagination.value });
            if (route.params.photoalbum && route.params.photoalbum !== '') {
                openDialog(route.params.photoalbum as string);
            }
        });

        return {
            t,
            photoAlbums,
            loadPhotoAlbums,
            pagination,
            loading,
            pagesNumber,
            formatDate,
            search,
            searchFilter,
            photoAlbumSlug,
            showPhotoAlbumDialog,
            openDialog,
            showCreatePhotoAlbumDialog,
        };
    },
});
