import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row no-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_icon, {
      size: "28px",
      name: "img:/img/nl.png",
      class: _normalizeClass(["language-flag q-mr-md", {
                'inactive-language': !_ctx.nl,
                'active-language-light': _ctx.nl && _ctx.lightBorder,
                'active-language-dark': _ctx.nl && !_ctx.lightBorder,
            }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.language = 'nl'))
    }, null, 8, ["class"]),
    _createVNode(_component_q_icon, {
      size: "28px",
      name: "img:/img/fr.png",
      class: _normalizeClass(["language-flag q-mr-md", {
                'inactive-language': !_ctx.fr,
                'active-language-light': _ctx.fr && _ctx.lightBorder,
                'active-language-dark': _ctx.fr && !_ctx.lightBorder,
            }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.language = 'fr'))
    }, null, 8, ["class"])
  ]))
}