import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row items-center q-mt-sm"
}
const _hoisted_2 = { class: "row col-xs-12 q-col-gutter-sm" }
const _hoisted_3 = { class: "row col-xs-12 q-col-gutter-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_date_selector = _resolveComponent("date-selector")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_language_toggle = _resolveComponent("language-toggle")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_user_selector = _resolveComponent("user-selector")!
  const _component_villas_selector = _resolveComponent("villas-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    small: "",
    visible: _ctx.showUserDialog,
    "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showUserDialog) = $event)),
    title: _ctx.t('User'),
    errors: _ctx.errors,
    "is-processing": _ctx.loading,
    "with-delete": "",
    "with-save": "",
    onSave: _ctx.saveUser,
    onDelete: _ctx.deleteUser,
    onClose: _ctx.closeUserDetailsDialog
  }, {
    default: _withCtx(() => [
      (_ctx.showSkeleton)
        ? (_openBlock(), _createBlock(_component_q_skeleton, {
            key: 0,
            type: "textarea",
            height: "500px"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_input, {
              ref: "usernameRef",
              modelValue: _ctx.user.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event)),
              class: "col-xs-12",
              label: _ctx.t('Name'),
              rules: [_ctx.required('Name'), _ctx.maxLength('Name', 255)]
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "person" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              ref: "emailRef",
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event)),
              label: _ctx.t('Email'),
              rules: [_ctx.required('Email'), _ctx.email],
              class: "col-xs-12"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "email" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_date_selector, {
              class: "col-xs-12",
              label: _ctx.t('Date of birth'),
              modelValue: _ctx.user.date_of_birth,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.date_of_birth) = $event)),
              clearable: ""
            }, null, 8, ["label", "modelValue"]),
            _createVNode(_component_q_input, {
              ref: "phoneNumberRef",
              modelValue: _ctx.user.phone_number,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.phone_number) = $event)),
              label: _ctx.t('Phone number'),
              rules: [_ctx.maxLength('Phone number', 255)],
              class: "col-xs-12",
              "hide-bottom-space": ""
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "phone" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              ref: "mobilePhoneNumberRef",
              modelValue: _ctx.user.mobile_phone_number,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.mobile_phone_number) = $event)),
              label: `${_ctx.t('Mobile phone number')}`,
              rules: [_ctx.maxLength('Mobile phone number', 255)],
              class: "col-xs-12",
              "hide-bottom-space": ""
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "phone" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_toggle, {
              class: "col-xs-12 col-sm-4 q-mt-md",
              modelValue: _ctx.user.is_admin,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.is_admin) = $event)),
              label: _ctx.t('Admin')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_language_toggle, {
              modelValue: _ctx.user.language,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.language) = $event)),
              class: "col-xs-12 col-sm-8 q-mt-md"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_q_separator, { class: "col-xs-12 q-mt-md q-mb-md" }),
            _createVNode(_component_user_selector, {
              user: _ctx.sameUser,
              "onUpdate:user": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sameUser) = $event)),
              label: _ctx.t('Same address and villas as (optional)'),
              class: "col-xs-12"
            }, null, 8, ["user", "label"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_input, {
                ref: "streetRef",
                modelValue: _ctx.user.address.street,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.address.street) = $event)),
                label: _ctx.t('Street'),
                rules: [_ctx.maxLength('Street', 255)],
                class: "col-xs-9",
                "hide-bottom-space": ""
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_q_input, {
                ref: "numberRef",
                modelValue: _ctx.user.address.number,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.address.number) = $event)),
                label: _ctx.t('Number'),
                rules: [_ctx.maxLength('Number', 255)],
                class: "col-xs-3",
                "hide-bottom-space": ""
              }, null, 8, ["modelValue", "label", "rules"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_input, {
                ref: "postalCodeRef",
                modelValue: _ctx.user.address.postal_code,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.address.postal_code) = $event)),
                label: _ctx.t('Postal code'),
                rules: [_ctx.maxLength('Postal code', 255)],
                class: "col-xs-6",
                "hide-bottom-space": ""
              }, null, 8, ["modelValue", "label", "rules"]),
              _createVNode(_component_q_input, {
                ref: "cityRef",
                modelValue: _ctx.user.address.city,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.address.city) = $event)),
                rules: [_ctx.maxLength('City', 255)],
                label: _ctx.t('City'),
                class: "col-xs-6",
                "hide-bottom-space": ""
              }, null, 8, ["modelValue", "rules", "label"])
            ]),
            _createVNode(_component_villas_selector, {
              class: "col-xs-12",
              modelValue: _ctx.user.villas,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.villas) = $event))
            }, null, 8, ["modelValue"])
          ]))
    ]),
    _: 1
  }, 8, ["visible", "title", "errors", "is-processing", "onSave", "onDelete", "onClose"]))
}