
import { ApiValidationError } from '@/api/core/api';
import PhotoAlbum from '@/api/models/PhotoAlbum';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import FileSelector from '@/components/Interaction/FileSelector.vue';
import { computed, defineComponent, Ref, ref, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import TranslatableInput from '@/components/Interaction/TranslatableInput.vue';
import { titleForOneLanguageIsRequiredNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: { CrudDialog, FileSelector, TranslatableInput },
    name: 'PhotoAlbumCreate',
    emits: ['created', 'update:modelValue'],
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (v: boolean) => {
                emit('update:modelValue', v);
            },
        });

        const newPhotoAlbum: Ref<PhotoAlbum> = ref(new PhotoAlbum({}));
        const photos: Ref<File[]> = ref([]);
        const language: Ref<string> = ref('nl');

        const isValidTitle = ref(false);
        const isValidDescription = ref(false);
        const isValidPhotoSelector = ref(false);
        const isValidForm = computed(
            () =>
                isValidTitle.value &&
                isValidDescription.value &&
                isValidPhotoSelector.value &&
                newPhotoAlbum.value.hasTitleForAtLeastOneLanguage()
        );

        const $q = useQuasar();
        const savePhotoAlbum = async () => {
            if (isValidForm.value) {
                isProcessing.value = true;
                try {
                    await newPhotoAlbum.value.save(photos.value);
                    visible.value = false;
                    newPhotoAlbum.value = new PhotoAlbum({});
                    photos.value = [];
                    emit('created');
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else if (!newPhotoAlbum.value.hasTitleForAtLeastOneLanguage()) {
                $q.notify(titleForOneLanguageIsRequiredNotification);
            }
        };

        return {
            t,
            visible,
            errors,
            isProcessing,
            newPhotoAlbum,
            savePhotoAlbum,
            language,
            photos,
            isValidPhotoSelector,
            isValidTitle,
            isValidDescription,
        };
    },
});
