
import { computed, defineComponent, WritableComputedRef } from '@vue/runtime-core';

export default defineComponent({
    name: 'Spinner',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isProcessing: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        return {
            isProcessing,
        };
    },
});
