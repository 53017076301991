
import User from '@/api/models/User';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import { defineComponent, ref, Ref, computed, watch, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { formatDatetime } from '@/util/formatters';
import UserSelector from '@/components/Selectors/UserSelector.vue';
import VillasSelector from '@/components/Selectors/VillasSelector.vue';
import { ApiValidationError } from '@/api/core/api';
import LanguageToggle from '@/components/Interaction/LanguageToggle.vue';
import { maxLength, required, email } from '@/validation/validation';
import QInput from 'quasar/src/components/input/QInput.js';;

export default defineComponent({
    name: 'UserDetails',
    components: { CrudDialog, VillasSelector, UserSelector, LanguageToggle },
    emits: ['update:visible', 'reload'],
    props: {
        /**
         * If the dialog is opened.
         */
        visible: {
            default: false,
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const router = useRouter();

        const loading: Ref<boolean> = ref(false);
        const user: Ref<User> = ref(new User({}));
        const showSkeleton = ref(false);
        const errors: Ref<string[]> = ref([]);
        const sameUser: Ref<User | null> = ref(null);

        const showUserCreateDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const closeUserCreateDialog = () => {
            user.value = new User({});
            sameUser.value = null;
            loading.value = false;
            errors.value = [];
            showUserCreateDialog.value = false;
        };

        const usernameRef = ref<QInput | null>(null);
        const userEmailRef = ref<QInput | null>(null);
        const isValidForm = () =>
            (usernameRef.value?.validate() ?? false) && (userEmailRef.value?.validate() ?? false);

        const saveUser = async () => {
            if (user.value !== null && isValidForm()) {
                try {
                    loading.value = true;
                    await user.value.save();
                    emit('reload');
                    router.replace({ name: 'user.overview' });
                    closeUserCreateDialog();
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    loading.value = false;
                }
            }
        };

        watch(
            () => sameUser.value,
            async () => {
                if (user.value !== null) {
                    const sameUserObject = sameUser.value
                        ? await User.get(sameUser.value.slug as string)
                        : null;

                    user.value.villas = sameUserObject ? sameUserObject.villas : [];
                }
            }
        );

        return {
            t,
            loading,
            errors,
            showUserCreateDialog,
            user,
            formatDatetime,
            sameUser,
            saveUser,
            closeUserCreateDialog,
            showSkeleton,
            maxLength,
            required,
            email,
            usernameRef,
            userEmailRef,
        };
    },
});
