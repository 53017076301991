import TranslatableText, { TranslatableTextData } from './TranslatableText';
import User, { UserData } from './User';

export interface ForumCommentData {
    uuid?: string;
    contents?: TranslatableTextData;
    user?: UserData;
    created_at?: string;
}

export default class ForumComment implements ForumCommentData {
    uuid?: string;
    contents?: TranslatableTextData;
    user?: User;
    created_at?: string;

    constructor(data: ForumCommentData) {
        this.uuid = data.uuid;
        this.contents = new TranslatableText(data.contents ?? {});
        this.user = new User(data.user ?? {});
        this.created_at = data.created_at;
    }
}
