import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-xs-12 col-sm-2 col-md-1 row" }
const _hoisted_2 = { class: "q-pt-xs q-ml-xs" }
const _hoisted_3 = {
  key: 0,
  class: "col-xs-12 col-sm-10 col-md-11 text-body1 truncatable-text"
}
const _hoisted_4 = {
  key: 1,
  class: "col-xs-12 col-sm-10 col-md-11 text-italic text-body1 truncatable-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!
  const _component_villa_detail = _resolveComponent("villa-detail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.searchedVillas,
      "onUpdate:objects": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchedVillas) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchQuery,
      "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event)),
      loading: _ctx.loading,
      title: _ctx.t('Villas'),
      "hide-create-button": "",
      "hide-pagination-controls": "",
      scrollable: "",
      onDetail: _cache[3] || (_cache[3] = ({ slug }) => _ctx.$router.push({ name: 'villa.overview', params: { villa: slug } }))
    }, {
      row: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_icon, {
            name: "house",
            class: "text-secondary",
            style: {"font-size":"2em"}
          }),
          _createElementVNode("div", _hoisted_2, _toDisplayString(props.object.number), 1)
        ]),
        (props.object.users.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(props.object.users.map((user) => user.name).join(', ')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('No owners yet')), 1))
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title"]),
    _createVNode(_component_villa_detail, {
      modelValue: _ctx.showDetailDialog,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDetailDialog) = $event)),
      villaNumber: _ctx.villaNumber
    }, null, 8, ["modelValue", "villaNumber"])
  ], 64))
}