const formatDate = (dateString: string): string => {
    if (dateString === undefined || dateString === null || dateString === '') return '-';
    return new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(new Date(dateString));
};

const formatDatetime = (datetimeString: string): string => {
    if (datetimeString === undefined || datetimeString === null || datetimeString === '')
        return '-';
    return new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(new Date(datetimeString));
};

export { formatDate, formatDatetime };
