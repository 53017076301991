
import { defineComponent, PropType, WritableComputedRef, computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Errors from '../Notifications/Errors.vue';
import DeleteConfirmDialog from '@/components/Interaction/DeleteConfirmDialog.vue';
import LanguageSelector from '@/components/Selectors/LanguageSelector.vue';
import TranslatableContent from '../Functional/TranslatableContent.vue';

export default defineComponent({
    components: { Errors, DeleteConfirmDialog, LanguageSelector, TranslatableContent },
    name: 'CrudDialog',
    emits: ['update:visible', 'update:language', 'close', 'save', 'delete'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        /**
         * Displayed at the top of the dialog.
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Validation errors as array of strings.
         */
        errors: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        /**
         * Whether the delete button should be visible.
         */
        withDelete: {
            type: Boolean,
            default: false,
        },
        languageSelect: {
            type: Boolean,
            default: false,
        },
        language: {
            type: String,
            default: 'nl',
        },
        translatableTitle: {
            type: Boolean,
            default: false,
        },
        /**
         * Wheter we should ask for a delete confirm.
         */
        deleteConfirm: {
            type: Boolean,
            default: true,
        },
        /**
         * Whether the save button should be visible.
         */
        withSave: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether an async action is currently running.
         */
        isProcessing: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the dialog should be large
         */
        large: {
            type: Boolean,
            default: true,
        },
        /**
         * Whether the dialog should be medium
         */
        medium: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the dialog should be small
         */
        small: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const show: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const languageObject: WritableComputedRef<string> = computed({
            get: () => props.language,
            set: (v: string) => {
                emit('update:language', v);
            },
        });

        const deleteConfirmDialog: Ref<boolean> = ref(false);

        return { t, show, languageObject, deleteConfirmDialog };
    },
});
