import i18n from '../i18n';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

const { t } = i18n.global;

type StringValidationFunction = (a: string) => boolean | string;

const required =
    (fieldName: string): StringValidationFunction =>
    (val: string) =>
        !!val || t('validation.required', { fieldName: t(fieldName) });

const email: StringValidationFunction = (val: string) => isEmail(val) || t('validation.email');

const maxLength =
    (fieldName: string, length: number): StringValidationFunction =>
    (val: string) =>
        val.length <= length || t('validation.maxLength', { fieldName: t(fieldName), length });

const selectAtLeastOne =
    <T>(fieldName: string) =>
    (val: Array<T> | Record<string, unknown> | null): boolean | string => {
        if (val instanceof Array) {
            return val.length > 0 || t('validation.atLeastOne', { name: t(fieldName) });
        } else if (val instanceof Object) {
            return true;
        } else {
            return t('validation.atLeastOne', { name: t(fieldName) });
        }
    };

const strongPassword: StringValidationFunction = (val: string) => {
    const options = {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
    };
    return isStrongPassword(val, options) || t('validation.strongPassword');
};

export { required, email, maxLength, selectAtLeastOne, strongPassword };
