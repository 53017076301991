
import User from '@/api/models/User';
import { MutationTypes } from '@/store/mutations';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'UserPartnerSelector',
    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
    },
    emits: ['update:user'],
    setup(props, { emit }) {
        const { t } = useI18n();

        const possibleUsers: Ref<User[] | null> = ref(null);
        const loadingUsers: Ref<boolean> = ref(true);

        const userModel: Ref<User | null> = computed({
            get: () => props.user,
            set: (value: User | null) => emit('update:user', value),
        });

        const loadUsers = async () => {
            const store = useStore();

            possibleUsers.value = store.getters.users;
            if (possibleUsers.value === null) {
                possibleUsers.value = await User.allSmall();
                store.commit(MutationTypes.setUsers, possibleUsers.value);
            }

            loadingUsers.value = false;
        };

        onMounted(loadUsers);

        return {
            t,
            loadingUsers,
            possibleUsers,
            userModel,
        };
    },
});
