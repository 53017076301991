import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    ref: "selectorRef",
    options: _ctx.commissions,
    modelValue: _ctx.commissionsModel,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.commissionsModel) = $event)),
      _ctx.validateSelector
    ],
    multiple: !_ctx.singleSelect,
    label: _ctx.singleSelect ? _ctx.t('Commission') : _ctx.t('Commissions'),
    "option-label": "name",
    clearable: "",
    loading: _ctx.loadingCommissions,
    "use-chips": "",
    "options-dense": "",
    rules: _ctx.required ? [_ctx.selectAtLeastOne('commission')] : []
  }, null, 8, ["options", "modelValue", "multiple", "label", "loading", "rules", "onUpdate:modelValue"]))
}