import User from '@/api/models/User';
import { State } from './state';

export default {
    user: (state: State): User | null => state.user,
    isAuthenticated: (state: State): boolean => state.user !== null,
    isAuthenticating: (state: State): boolean => state.isAuthenticating,
    isAdmin: (state: State): boolean => state.user?.is_admin || false,
    users: (state: State): User[] | null => state.users,
    displayLanguage: (state: State): string => state.displayLanguage,
    isMenuOpen: (state: State): boolean => state.isMenuOpen,
};
