import User from '@/api/models/User';

export interface State {
    displayLanguage: string;
    user: User | null;
    isAuthenticating: boolean;
    users: User[] | null;
    isMenuOpen: boolean;
}

const state: State = {
    displayLanguage: 'nl',
    user: null,
    isAuthenticating: false,
    users: null,
    isMenuOpen: false,
};

export default state;
