
import { ApiAuthorizationError, ApiValidationError } from '@/api/core/api';
import DocumentArchive from '@/api/models/DocumentArchive';
import { computed, defineComponent, Ref, ref, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import CommissionsSelector from '@/components/Selectors/CommissionsSelector.vue';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import FileSelector from '@/components/Interaction/FileSelector.vue';
import DateSelector from '@/components/Selectors/DateSelector.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import { useStore } from 'vuex';
import { missingTranslationNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: { CrudDialog, CommissionsSelector, FileSelector, DateSelector, TranslatableInput },
    name: 'DocumentArchiveCreate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:modelValue', 'created'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();

        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);
        const user = computed(() => store.getters.user);

        const newDocumentArchive: Ref<DocumentArchive> = ref(new DocumentArchive({}));
        const language: Ref<string> = ref('nl');

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        const documents: Ref<File[]> = ref([]);

        const isValidTitle = ref(false);
        const isValidCommissionSelector = ref(false);
        const isValidDocumentsSelector = ref(false);
        const isFormValid = computed(
            () =>
                isValidTitle.value &&
                isValidCommissionSelector.value &&
                isValidDocumentsSelector.value
        );

        const $q = useQuasar();
        const saveDocumentArchive = async () => {
            if (isFormValid.value) {
                isProcessing.value = true;
                try {
                    await newDocumentArchive.value.save(documents.value);
                    visible.value = false;
                    emit('created');
                    clearInput();
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else if (e instanceof ApiAuthorizationError) {
                        errors.value = [
                            t('Only members of commissions can create document archives.'),
                        ];
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else if (!isValidTitle.value) {
                $q.notify(missingTranslationNotification);
            }
        };

        const clearInput = () => {
            newDocumentArchive.value = new DocumentArchive({});
            documents.value = [];
        };

        return {
            visible,
            errors,
            isProcessing,
            t,
            user,
            newDocumentArchive,
            documents,
            saveDocumentArchive,
            language,
            isValidTitle,
            isValidCommissionSelector,
            isValidDocumentsSelector,
        };
    },
});
