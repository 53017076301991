
import { ApiAuthorizationError, ApiValidationError } from '@/api/core/api';
import DocumentArchive from '@/api/models/DocumentArchive';
import { CommissionData } from '@/api/models/Commission';
import {
    computed,
    defineComponent,
    Ref,
    ref,
    watch,
    WritableComputedRef,
    onBeforeMount,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import CommissionsSelector from '@/components/Selectors/CommissionsSelector.vue';
import CrudDialog from '../../components/Interaction/CrudDialog.vue';
import FileSelector from '../../components/Interaction/FileSelector.vue';
import Documents from '@/components/Documents.vue';
import DateSelector from '@/components/Selectors/DateSelector.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import { missingTranslationNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: {
        CrudDialog,
        DateSelector,
        TranslatableInput,
        CommissionsSelector,
        FileSelector,
        Documents,
    },
    name: 'DocumentArchiveUpdate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        /**
         * Slug of the document archive.
         */
        documentArchiveSlug: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue', 'reload'],
    setup(props, { emit }) {
        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);
        const { t } = useI18n();

        const selectedCommission: Ref<CommissionData | null> = ref(null);
        const documents: Ref<File[]> = ref([]);
        const documentArchiveToUpdate: Ref<DocumentArchive | null> = ref(null);
        const language: Ref<string> = ref('nl');

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        const numAlreadyUploadedFiles = computed(() => {
            if (documentArchiveToUpdate.value) {
                return documentArchiveToUpdate.value.documents?.length || 0;
            }
            return 0;
        });

        const loadDocumentArchive = async () => {
            errors.value = [];
            documentArchiveToUpdate.value = null;
            documents.value = [];
            if (props.documentArchiveSlug) {
                documentArchiveToUpdate.value = await DocumentArchive.get(
                    props.documentArchiveSlug as string
                );
                selectedCommission.value = documentArchiveToUpdate.value.commission || null;
            }
        };

        const isValidTitle = ref(true);
        const isValidCommissionSelector = ref(true);
        const isFormValid = computed(() => isValidTitle.value && isValidCommissionSelector.value);

        const $q = useQuasar();
        const saveDocumentArchive = async () => {
            if (documentArchiveToUpdate.value === null) return;

            if (isFormValid.value) {
                isProcessing.value = true;
                try {
                    await documentArchiveToUpdate.value.save(documents.value);
                    visible.value = false;
                    emit('reload');
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else if (e instanceof ApiAuthorizationError) {
                        errors.value = [t('You can only update your own document archives.')];
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else if (!isValidTitle.value) {
                $q.notify(missingTranslationNotification);
            }
        };

        const deleteDocumentArchive = async () => {
            if (documentArchiveToUpdate.value !== null) {
                isProcessing.value = true;
                await documentArchiveToUpdate.value.delete();
                emit('reload');
                isProcessing.value = false;
                visible.value = false;
            }
        };

        watch(
            () => props.documentArchiveSlug,
            async () => await loadDocumentArchive()
        );

        watch(selectedCommission, (updatedCommission: CommissionData | null) => {
            if (documentArchiveToUpdate === null) return;
            documentArchiveToUpdate.value!.commission = updatedCommission || undefined;
        });

        onBeforeMount(async () => await loadDocumentArchive());

        return {
            visible,
            errors,
            isProcessing,
            t,
            documentArchiveToUpdate,
            selectedCommission,
            documents,
            numAlreadyUploadedFiles,
            deleteDocumentArchive,
            saveDocumentArchive,
            loadDocumentArchive,
            language,
            isValidTitle,
            isValidCommissionSelector,
        };
    },
});
