
import { defineComponent, computed } from 'vue';
import AppMenu from './components/Layout/AppMenu.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Spinner from './components/Util/Spinner.vue';

export default defineComponent({
    components: { AppMenu, Spinner },
    name: 'App',

    setup() {
        const store = useStore();
        const i18n = useI18n();
        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const isAuthenticating = computed(() => store.getters.isAuthenticating);

        return {
            t: i18n.t,
            isAuthenticated,
            isAuthenticating,
        };
    },
});
