export interface TranslatableTextData {
    nl?: string;
    fr?: string;
}

export default class TranslatableText implements TranslatableTextData {
    nl?: string;
    fr?: string;

    constructor(data: TranslatableTextData) {
        this.nl = data.nl || '';
        this.fr = data.fr || '';
    }

    static getJson(text?: TranslatableText): string {
        return JSON.stringify(text);
    }
}
