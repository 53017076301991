import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row q-mt-sm" }
const _hoisted_2 = { class: "row col-xs-12 items-center" }
const _hoisted_3 = { class: "row col-xs-12 items-center" }
const _hoisted_4 = { class: "row col-xs-12 items-center" }
const _hoisted_5 = {
  key: 0,
  class: "row q-mt-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_documents = _resolveComponent("documents")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    visible: _ctx.showDocumentArchiveDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDocumentArchiveDialog) = $event)),
    title: _ctx.documentarchive?.title,
    translatableTitle: "",
    errors: [],
    "is-processing": false,
    "with-delete": false,
    "with-save": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_icon, {
            name: "group",
            class: "q-mr-xs"
          }),
          (!_ctx.documentarchive)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.documentarchive.commission.name), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_icon, {
            name: "event",
            class: "q-mr-xs"
          }),
          (!_ctx.documentarchive)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.t('Meeting date')) + ": " + _toDisplayString(_ctx.formatDate(_ctx.documentarchive.meeting_date)), 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_icon, {
            name: "event",
            class: "q-mr-xs"
          }),
          (!_ctx.documentarchive)
            ? (_openBlock(), _createBlock(_component_q_skeleton, {
                key: 0,
                class: "col-xs-4 col-md-2",
                type: "text"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.t('Upload date')) + ": " + _toDisplayString(_ctx.formatDatetime(_ctx.documentarchive.created_at)), 1)
              ], 64))
        ])
      ]),
      (_ctx.documentarchive && _ctx.documentarchive.documents.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_documents, { documentArchive: _ctx.documentarchive }, null, 8, ["documentArchive"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}