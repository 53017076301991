import api, { PaginatedApiMeta } from '@/api/core/api';
import Address, { AddressData } from './Address';
import Commission from './Commission';
import Villa, { VillaData } from './Villa';

export enum UserLanguage {
    NL = 'nl',
    FR = 'fr',
}

export interface UserData {
    uuid?: string;
    name?: string;
    slug?: string;
    email?: string;
    date_of_birth?: string;
    occupation?: string;
    phone_number?: string;
    mobile_phone_number?: string;
    hobbies?: string;
    language?: UserLanguage;
    is_admin?: boolean;
    is_commission_member?: boolean;
    commissions?: Commission[];
    avatar?: string;
    title?: string;
    villas?: VillaData[];
    address?: AddressData;
    receive_forum_post_notifications?: boolean;
}

export interface PasswordData {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}

export default class User implements UserData {
    uuid?: string;
    name?: string;
    slug?: string;
    email?: string;
    date_of_birth?: string;
    occupation?: string;
    phone_number?: string;
    mobile_phone_number?: string;
    hobbies?: string;
    language?: UserLanguage;
    is_admin?: boolean;
    is_commission_member?: boolean;
    commissions?: Commission[];
    avatar?: string;
    title?: string;
    villas?: Villa[];
    address?: Address;
    receive_forum_post_notifications?: boolean;

    constructor(data: UserData) {
        this.uuid = data.uuid;
        this.name = data.name;
        this.slug = data.slug;
        this.email = data.email;
        this.date_of_birth = data.date_of_birth ?? '';
        this.occupation = data.occupation;
        this.phone_number = data.phone_number;
        this.mobile_phone_number = data.mobile_phone_number;
        this.hobbies = data.hobbies;
        this.language = data.language ?? UserLanguage.NL;
        this.is_admin = data.is_admin ?? false;
        this.is_commission_member = data.is_commission_member;
        this.commissions = data.commissions;
        this.avatar = data.avatar;
        this.title = data.title;
        this.villas = data.villas?.map((villa) => new Villa(villa)) || [];
        this.address = data.address || new Address({});
        this.receive_forum_post_notifications = data.receive_forum_post_notifications;
    }

    static async signIn(email: string, password: string): Promise<User> {
        const response = await api.post<UserData>('/login', { email, password });
        return new User(response.data);
    }

    static async signOut(): Promise<void> {
        await api.post<UserData>('/logout');
    }

    static async user(): Promise<User> {
        const response = await api.get<UserData>('/user');
        return new User(response.data);
    }

    static async all(
        config: {
            search?: string;
            pageNumber?: number;
        } = {}
    ): Promise<{ users: User[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<UserData[]>('/users', {
            search: config.search || null,
            page: config.pageNumber,
        });

        return {
            users: response.data.map((user) => new User(user)),
            meta: response.meta,
        };
    }

    static async allSmall(): Promise<User[]> {
        const response = await api.getPaginated<User[]>('/users/overview');
        return response.data.map((user) => new User(user));
    }

    static async get(slug: string): Promise<User> {
        const response = await api.get<User>(`/users/${slug}`);
        return new User(response.data);
    }

    async save(): Promise<User> {
        const requestData = {
            name: this.name,
            email: this.email,
            date_of_birth: this.date_of_birth,
            is_admin: this.is_admin,
            hobbies: this.hobbies,
            language: this.language,
            address: {
                street: this.address?.street,
                number: this.address?.number,
                postal_code: this.address?.postal_code,
                city: this.address?.city,
            },
            villas: this.villas?.map((villa) => villa.uuid),
            mobile_phone_number: this.mobile_phone_number,
            phone_number: this.phone_number,
            receive_forum_post_notifications: this.receive_forum_post_notifications,
        };

        const response = this.uuid
            ? await api.patch<User>(`/users/${this.slug}`, requestData)
            : await api.post<User>('/users', requestData);
        return new User(response.data);
    }

    async delete(): Promise<void> {
        await api.delete(`/users/${this.slug}`);
    }

    async updatePassword(password: {
        current: string;
        new: string;
        confirmation: string;
    }): Promise<void> {
        const payload = {
            current_password: password.current,
            new_password: password.new,
            new_password_confirmation: password.confirmation,
        };
        await api.post<void>('user/update-password', payload);
    }

    static async uploadAvatar(avatar: File): Promise<User> {
        const fd = new FormData();
        fd.append('avatar', avatar);

        const response = await api.post<User>('user/upload-avatar', fd);
        return new User(response.data);
    }
}
