
import { computed, defineComponent, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'LanguageToggle',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const language: WritableComputedRef<string> = computed({
            get: () => props.modelValue,
            set: (lang: string) => {
                emit('update:modelValue', lang);
            },
        });

        return {
            t,
            language,
        };
    },
});
