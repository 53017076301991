import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-grow text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "crud-dialog-card-extra-small",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "row items-center" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('Are you sure you want to delete this?')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { class: "q-pa-md" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                flat: "",
                label: _ctx.t('Cancel'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false)),
                "text-color": "negative"
              }, null, 8, ["label"]),
              _createVNode(_component_q_space),
              _createVNode(_component_q_btn, {
                label: _ctx.t('Confirm delete'),
                color: "secondary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {
                        _ctx.show = false;
                        _ctx.$emit('delete');
                    }),
                class: "q-ml-md"
              }, null, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}