import api from '@/api/core/api';

export default class Password {
    static async forgotten(email: string): Promise<boolean> {
        const response = await api.post<{ success: boolean }>('/forgot-password', { email: email });

        return response.data.success;
    }

    static async reset(
        email: string,
        password: string,
        password_confirmation: string,
        token: string
    ): Promise<boolean> {
        const response = await api.post<{ success: boolean }>('/reset-password', {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            token: token,
        });

        return response.data.success;
    }
}
