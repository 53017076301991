
import ForumPost from '@/api/models/ForumPost';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import ForumCreate from '@/views/Forum/ForumCreate.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDatetime } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';

export default defineComponent({
    name: 'ForumOverview',
    components: { OverviewPageTableLayout, TranslatableContent, ForumCreate },
    setup() {
        const { t } = useI18n();

        const loading = ref(true);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const forumPosts: Ref<ForumPost[]> = ref([]);
        const loadForumPosts = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;

            forumPosts.value = [];

            const response = await ForumPost.all({
                search: searchFilter.value || undefined,
                sortOnProperty: props.pagination.sortBy,
                ascending: !props.pagination.descending,
                numberPerPage: props.pagination.rowsPerPage,
                pageNumber: props.pagination.page,
            });

            forumPosts.value = response.forumPosts;

            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loading.value = false;
        };

        const sortOptions = [
            {
                name: t('Date (latest first)'),
                property: 'created_at',
                descending: true,
            },
            {
                name: t('Date (oldest first)'),
                property: 'created_at',
                descending: false,
            },
            {
                name: t('Title'),
                property: 'title',
                descending: false,
            },
            {
                name: t('Views'),
                property: 'views',
                descending: true,
            },
            {
                name: t('Comments'),
                property: 'comments',
                descending: true,
            },
        ];

        const sort = ref(sortOptions[0]);
        const searchFilter = ref(null);

        const showCreateDialog = ref(false);

        const search = () => {
            (pagination.value.sortBy = sort.value.property),
                (pagination.value.descending = sort.value.descending);
            loadForumPosts({ pagination: pagination.value });
        };

        watch(
            () => sort.value,
            () => {
                (pagination.value.sortBy = sort.value.property),
                    (pagination.value.descending = sort.value.descending);
                loadForumPosts({ pagination: pagination.value });
            }
        );

        onBeforeMount(() => {
            sort.value = sortOptions[0];
            loadForumPosts({ pagination: pagination.value });
        });

        return {
            t,
            forumPosts,
            loadForumPosts,
            pagination,
            loading,
            pagesNumber,
            formatDatetime,
            sort,
            sortOptions,
            search,
            searchFilter,
            showCreateDialog,
        };
    },
});
