
import ForumPost from '@/api/models/ForumPost';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import Spinner from '@/components/Util/Spinner.vue';
import { defineComponent, ref, Ref, WritableComputedRef, computed } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { ApiValidationError } from '@/api/core/api';
import TranslatableInput from '@/components/Interaction/TranslatableInput.vue';
import { titleForOneLanguageIsRequiredNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    name: 'ForumCreate',
    components: { CrudDialog, Spinner, TranslatableInput },
    emits: ['update:visible', 'reload'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showCreateDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const processingCreatingPost = ref(false);
        const newForumPost: Ref<ForumPost> = ref(new ForumPost({}));

        const isValidTitle = ref(false);
        const isValidDescription = ref(false);
        const isValid = computed(
            () =>
                isValidTitle.value &&
                isValidDescription.value &&
                newForumPost.value.hasTitleForAtLeastOneLanguage()
        );

        const language: Ref<string> = ref('nl');
        const errors: Ref<string[]> = ref([]);

        const $q = useQuasar();
        const createForumPost = async () => {
            if (isValid.value) {
                processingCreatingPost.value = true;
                errors.value = [];
                try {
                    await newForumPost.value.save();
                    showCreateDialog.value = false;
                    newForumPost.value = new ForumPost({});
                    emit('reload');
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                        return;
                    } else {
                        throw e;
                    }
                } finally {
                    processingCreatingPost.value = false;
                }
            } else if (!newForumPost.value.hasTitleForAtLeastOneLanguage()) {
                $q.notify(titleForOneLanguageIsRequiredNotification);
            }
        };

        return {
            t,
            showCreateDialog,
            newForumPost,
            createForumPost,
            processingCreatingPost,
            errors,
            language,
            isValidTitle,
            isValidDescription,
        };
    },
});
