import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-xs-8 col-sm-10" }
const _hoisted_2 = { class: "col-xs-12 row text-body1" }
const _hoisted_3 = { class: "row text-caption items-center justify-start" }
const _hoisted_4 = { class: "row items-center col-xs-12" }
const _hoisted_5 = { class: "col-xs-4 col-sm-2 justify-end" }
const _hoisted_6 = { class: "row text-body2 items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_confirm_dialog = _resolveComponent("delete-confirm-dialog")!
  const _component_information_document_create = _resolveComponent("information-document-create")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_delete_confirm_dialog, {
      visible: _ctx.showDeleteConfirmDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDeleteConfirmDialog) = $event)),
      onDelete: _ctx.deleteInformationDocument
    }, null, 8, ["visible", "onDelete"]),
    _createVNode(_component_information_document_create, {
      modelValue: _ctx.showInformationDocumentCreateDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showInformationDocumentCreateDialog) = $event)),
      onCreated: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadInformationDocuments({ pagination: _ctx.pagination })))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.informationDocuments,
      "onUpdate:objects": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.informationDocuments) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      "onUpdate:loading": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.loading) = $event)),
      title: _ctx.t('Information documents'),
      createTitle: _ctx.t('Upload document'),
      onReload: _cache[7] || (_cache[7] = ($event: any) => (_ctx.loadInformationDocuments({ pagination: _ctx.pagination }))),
      hideCreateButton: !_ctx.isAdmin,
      onCreate: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showInformationDocumentCreateDialog = !_ctx.showInformationDocumentCreateDialog)),
      onDetail: _cache[9] || (_cache[9] = 
            ({ slug }) =>
                _ctx.$router.push({
                    name: 'information-documents.overview',
                    params: { informationdocument: slug },
                })
        )
    }, {
      row: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_translatable_content, {
              text: props.object.title,
              truncate: ""
            }, null, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "q-mr-xs"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(props.object.created_at)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_btn, {
              onClick: _withModifiers(($event: any) => (_ctx.downloadDocument(props.object.document.url)), ["prevent"]),
              icon: "file_download",
              color: "secondary",
              size: "sm",
              padding: "xs"
            }, null, 8, ["onClick"]),
            (_ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  class: "q-ml-sm",
                  onClick: _withModifiers(($event: any) => {
                            _ctx.informationDocumentToDelete = props.object;
                            _ctx.showDeleteConfirmDialog = true;
                        }, ["prevent"]),
                  icon: "delete",
                  color: "negative",
                  size: "sm",
                  padding: "xs"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle", "hideCreateButton"])
  ], 64))
}