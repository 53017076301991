
import User from '@/api/models/User';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import {
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    computed,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { formatDatetime } from '@/util/formatters';
import UserSelector from '@/components/Selectors/UserSelector.vue';
import VillasSelector from '@/components/Selectors/VillasSelector.vue';
import DateSelector from '@/components/Selectors/DateSelector.vue';
import Address from '@/api/models/Address';
import { ApiValidationError } from '@/api/core/api';
import LanguageToggle from '@/components/Interaction/LanguageToggle.vue';
import QInput from 'quasar/src/components/input/QInput.js';;
import { required, email, maxLength } from '@/validation/validation';

export default defineComponent({
    name: 'UserDetails',
    components: { CrudDialog, VillasSelector, UserSelector, DateSelector, LanguageToggle },
    emits: ['update:visible', 'reload'],
    props: {
        /**
         * If the dialog is opened.
         */
        visible: {
            default: false,
            type: Boolean,
        },
        /**
         * Slug of the user.
         */
        userSlug: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const router = useRouter();

        const loading: Ref<boolean> = ref(false);
        const user: Ref<User> = ref(new User({}));
        const showSkeleton = ref(false);
        const sameUser: Ref<User | null> = ref(null);
        const errors: Ref<string[]> = ref([]);

        const showUserDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const loadUserFromSlug = async () => {
            if (props.userSlug !== null) {
                showSkeleton.value = true;
                user.value = await User.get(props.userSlug as string);
                showSkeleton.value = false;
            }
        };

        const closeUserDetailsDialog = () => {
            user.value = new User({});
            sameUser.value = null;
            loading.value = false;
            errors.value = [];
            showUserDialog.value = false;
        };

        const usernameRef = ref<QInput | null>(null);
        const emailRef = ref<QInput | null>(null);
        const phoneNumberRef = ref<QInput | null>(null);
        const mobilePhoneNumberRef = ref<QInput | null>(null);
        const streetRef = ref<QInput | null>(null);
        const numberRef = ref<QInput | null>(null);
        const postalCodeRef = ref<QInput | null>(null);
        const cityRef = ref<QInput | null>(null);

        const isValidForm = () => {
            return (
                (usernameRef.value?.validate() ?? false) &&
                (emailRef.value?.validate() ?? false) &&
                (phoneNumberRef.value?.validate() ?? false) &&
                (mobilePhoneNumberRef.value?.validate() ?? false) &&
                (streetRef.value?.validate() ?? false) &&
                (numberRef.value?.validate() ?? false) &&
                (postalCodeRef.value?.validate() ?? false)
            );
        };

        const saveUser = async () => {
            if (user.value !== null && isValidForm()) {
                try {
                    loading.value = true;
                    await user.value.save();
                    emit('reload');
                    router.replace({ name: 'user.overview' });
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    closeUserDetailsDialog();
                }
            }
        };

        const deleteUser = async () => {
            if (user.value !== null) {
                loading.value = true;
                await user.value.delete();
                emit('reload');
                closeUserDetailsDialog();
            }
        };

        watch(
            () => props.userSlug,
            async () => {
                loadUserFromSlug();
            }
        );

        watch(
            () => sameUser.value,
            async () => {
                if (user.value !== null) {
                    const sameUserObject = sameUser.value
                        ? await User.get(sameUser.value.slug as string)
                        : null;
                    if (user.value) {
                        user.value.address = sameUserObject
                            ? sameUserObject.address
                            : new Address({});
                        user.value.villas = sameUserObject ? sameUserObject.villas : [];
                    }
                }
            }
        );

        onBeforeMount(async () => loadUserFromSlug());

        return {
            t,
            loading,
            errors,
            showUserDialog,
            user,
            formatDatetime,
            sameUser,
            saveUser,
            deleteUser,
            closeUserDetailsDialog,
            showSkeleton,
            usernameRef,
            emailRef,
            phoneNumberRef,
            mobilePhoneNumberRef,
            streetRef,
            numberRef,
            postalCodeRef,
            cityRef,
            required,
            email,
            maxLength,
        };
    },
});
