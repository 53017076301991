
import Commission from '@/api/models/Commission';
import User from '@/api/models/User';
import Spinner from '@/components/Util/Spinner.vue';
import { defineComponent, onBeforeMount, ref, Ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import CommissionDetails from './CommissionDetails.vue';

export default defineComponent({
    name: 'Commissions',
    components: { Spinner, CommissionDetails },
    setup() {
        const { t } = useI18n();

        const loading = ref(true);

        const commissions: Ref<Commission[]> = ref([]);
        const loadCommissions = async () => {
            loading.value = true;

            commissions.value = [];
            commissions.value = await Commission.all();

            loading.value = false;
        };

        onBeforeMount(async () => await loadCommissions());

        const store = useStore();
        const user: User = store.getters.user;

        const showDetailsDialog = ref(false);

        const commission: Ref<Commission | null> = ref(null);
        const openCommissionUpdateDialog = (selectedCommission: Commission) => {
            commission.value = selectedCommission;
            showDetailsDialog.value = true;
        };

        const showDetailsDialogAsCreate = () => {
            commission.value = null;
            showDetailsDialog.value = true;
        };

        return {
            t,
            commissions,
            loading,
            user,
            showDetailsDialog,
            openCommissionUpdateDialog,
            commission,
            showDetailsDialogAsCreate,
            loadCommissions,
        };
    },
});
