
import { ApiAuthorizationError, ApiValidationError } from '@/api/core/api';
import InformationDocument from '@/api/models/InformationDocument';
import { computed, defineComponent, Ref, ref, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import FileSelector from '@/components/Interaction/FileSelector.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import { useStore } from 'vuex';
import { missingTranslationNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: { CrudDialog, FileSelector, TranslatableInput },
    name: 'InformationDocumentCreate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:modelValue', 'created'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();

        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);
        const user = computed(() => store.getters.user);

        const newInformationDocument: Ref<InformationDocument> = ref(new InformationDocument({}));
        const language: Ref<string> = ref('nl');

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        const document: Ref<File | null> = ref(null);

        const isValidTitle = ref(false);
        const isValidDocumentSelector = ref(false);
        const isFormValid = computed(() => isValidTitle.value && isValidDocumentSelector.value);

        const $q = useQuasar();
        const saveInformationDocument = async () => {
            if (isFormValid.value) {
                isProcessing.value = true;
                try {
                    if (document.value) {
                        await newInformationDocument.value.save(document.value);
                        visible.value = false;
                        emit('created');
                        clearInput();
                    }
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else if (e instanceof ApiAuthorizationError) {
                        errors.value = [t('Only admins can create information document.')];
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else if (!isValidTitle.value) {
                $q.notify(missingTranslationNotification);
            }
        };

        const clearInput = () => {
            newInformationDocument.value = new InformationDocument({});
            document.value = null;
        };

        return {
            visible,
            errors,
            isProcessing,
            t,
            user,
            newInformationDocument,
            document,
            saveInformationDocument,
            language,
            isValidTitle,
            isValidDocumentSelector,
        };
    },
});
