import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_input, {
      modelValue: _ctx.translatableText.nl,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translatableText.nl) = $event)),
        _ctx.validateForm
      ],
      ref: "inputRefNl",
      label: _ctx.title,
      type: _ctx.type,
      rules: 
            _ctx.optional
                ? [_ctx.maxLength(_ctx.title, _ctx.type === 'text' ? 255 : 50000)]
                : [_ctx.required(_ctx.title), _ctx.maxLength(_ctx.title, _ctx.type === 'text' ? 255 : 50000)]
        ,
      "reactive-rules": ""
    }, null, 8, ["modelValue", "label", "type", "rules", "onUpdate:modelValue"]), [
      [_vShow, _ctx.language == 'nl']
    ]),
    _withDirectives(_createVNode(_component_q_input, {
      modelValue: _ctx.translatableText.fr,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.translatableText.fr) = $event)),
        _ctx.validateForm
      ],
      ref: "inputRefFr",
      label: _ctx.title,
      type: _ctx.type,
      rules: 
            _ctx.optional
                ? [_ctx.maxLength(_ctx.title, _ctx.type === 'text' ? 255 : 50000)]
                : [_ctx.required(_ctx.title), _ctx.maxLength(_ctx.title, _ctx.type === 'text' ? 255 : 50000)]
        ,
      "reactive-rules": ""
    }, null, 8, ["modelValue", "label", "type", "rules", "onUpdate:modelValue"]), [
      [_vShow, _ctx.language == 'fr']
    ])
  ], 64))
}