{
    "Authenticating": "Authenticating",
    "Delete": "Delete",
    "Save": "Save",
    "Are you sure you want to delete this?": "Are you sure you want to delete this?",
    "Cancel": "Cancel",
    "Confirm delete": "Confirm delete",
    "Password forgotten": "Password forgotten",
    "Reset password": "Reset password",
    "Email": "Email",
    "Password reset request succesful. Check your email for the reset link.": "Password reset request succesful. Check your email for the reset link.",
    "Hide": "Hide",
    "Announcements": "Announcements",
    "Documents": "Documents",
    "Forum": "Forum",
    "Photos": "Photos",
    "Commissions": "Commissions",
    "Users": "Users",
    "Villas": "Villas",
    "Villa": "Villa",
    "Search": "Search",
    "No data was found": "No data was found",
    "Commission": "Commission",
    "Clear": "Clear",
    "Close": "Close",
    "User": "User",
    "Owner of house(s)": "Owner of house(s)",
    "Owner": "Owner",
    "Owners": "Owners",
    "Home address": "Home address",
    "Telephone number": "Telephone number",
    "Mobile phone number": "Mobile phone number",
    "Hobbies": "Hobbies",
    "Date of birth": "Date of birth",
    "Account information": "Account information",
    "New avatar": "New avatar",
    "Change password": "Change password",
    "Current password": "Current password",
    "New password": "New password",
    "Password confirmation": "Password confirmation",
    "Change": "Change",
    "Personal information": "Personal information",
    "Phone number": "Phone number",
    "Receive forum post notifications": "Receive forum post notifications",
    "Street": "Street",
    "Number": "Number",
    "Postal code": "Postal code",
    "City": "City",
    "Create new announcement": "Create new announcement",
    "Title": "Title",
    "Contents": "Contents",
    "Upload documents (optional)": "Upload documents (optional)",
    "Announcement": "Announcement",
    "Create announcement": "Create announcement",
    "Update announcement": "Update announcement",
    "Upload additional documents (optional)": "Upload additional documents (optional)",
    "Only members of commissions can update their own announcements.": "Only members of commissions can update their own announcements.",
    "Edit a commission": "Edit a commission",
    "Create new commission": "Create new commission",
    "Name": "Name",
    "Add member": "Add member",
    "Create commission": "Create commission",
    "Edit": "Edit",
    "Create new document archive": "Create new document archive",
    "Meeting date": "Meeting date",
    "Upload date": "Upload date",
    "Document archives": "Document archives",
    "Create archive": "Create archive",
    "Update document archive": "Update document archive",
    "Create a new forum post": "Create a new forum post",
    "Forum message": "Forum message",
    "Delete post": "Delete post",
    "Type your comment here": "Type your comment here",
    "Add comment": "Add comment",
    "Create post": "Create post",
    "Sort by": "Sort by",
    "Date (latest first)": "Date (latest first)",
    "Date (oldest first)": "Date (oldest first)",
    "Views": "Views",
    "Comments": "Comments",
    "Create a new photo album": "Create a new photo album",
    "Description": "Description",
    "Photo albums": "Photo albums",
    "Create album": "Create album",
    "Ownersportal": "Ownersportal",
    "Password": "Password",
    "Reset": "Reset",
    "Sign in": "Sign in",
    "These credentials are wrong": "These credentials are wrong",
    "Admin": "Admin",
    "Same address and villas as (optional)": "Same address and villas as (optional)",
    "Create user": "Create user",
    "No owners yet": "No owners yet",
    "Logout": "Logout",
    "validation": {
        "required": "{fieldName} is required",
        "email": "Please enter a valid email address",
        "maxLength": "{fieldName} cannot be larger than {length} characters",
        "atLeastOne": "Select at least one {name}",
        "strongPassword": "Passwords should contain at least 8 characters, a letter, a capital letter and a number."
    },
    "commission": "commission",
    "document": "document",
    "photo": "photo",
    "Please fill in both translations.": "Please fill in both translations.",
    "Password confirmation is not equal to new password.": "Password confirmation is not equal to new password.",
    "Please choose an image first": "Please choose an image first",
    "Fill in a title for at least one language.": "Fill in a title for at least one language.",
    "Fill in a comment for for at least one language.": "Fill in a comment for for at least one language."
}
