import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    medium: "",
    languageSelect: "",
    language: _ctx.language,
    "onUpdate:language": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.language) = $event)),
    visible: _ctx.visible,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.t('Create a new photo album'),
    "with-save": "",
    onSave: _ctx.savePhotoAlbum,
    onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    "is-processing": _ctx.isProcessing,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _createVNode(_component_translatable_input, {
        modelValue: _ctx.newPhotoAlbum.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPhotoAlbum.title) = $event)),
        "is-valid": _ctx.isValidTitle,
        "onUpdate:is-valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValidTitle) = $event)),
        language: _ctx.language,
        title: _ctx.t('Title'),
        type: "text",
        optional: ""
      }, null, 8, ["modelValue", "is-valid", "language", "title"]),
      _createVNode(_component_translatable_input, {
        modelValue: _ctx.newPhotoAlbum.description,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPhotoAlbum.description) = $event)),
        "is-valid": _ctx.isValidDescription,
        "onUpdate:is-valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isValidDescription) = $event)),
        language: _ctx.language,
        title: _ctx.t('Description'),
        type: "textarea",
        optional: ""
      }, null, 8, ["modelValue", "is-valid", "language", "title"]),
      _createVNode(_component_file_selector, {
        modelValue: _ctx.photos,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.photos) = $event)),
        "is-valid": _ctx.isValidPhotoSelector,
        "onUpdate:is-valid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isValidPhotoSelector) = $event)),
        label: _ctx.t('Photos'),
        required: "",
        images: "",
        class: "q-mt-md"
      }, null, 8, ["modelValue", "is-valid", "label"])
    ]),
    _: 1
  }, 8, ["language", "visible", "title", "onSave", "is-processing", "errors"]))
}