export interface AddressData {
    uuid?: string;
    street?: string;
    number?: string;
    postal_code?: string;
    city?: string;
}

export default class Address implements AddressData {
    uuid?: string;
    street?: string;
    number?: string;
    postal_code?: string;
    city?: string;

    constructor(data: AddressData) {
        this.uuid = data.uuid;
        this.street = data.street ?? '';
        this.number = data.number ?? '';
        this.postal_code = data.postal_code ?? '';
        this.city = data.city ?? '';
    }
}
