import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "q-ml-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_delete_confirm_dialog = _resolveComponent("delete-confirm-dialog")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_spinner, {
      modelValue: _ctx.isProcessing,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isProcessing) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_delete_confirm_dialog, {
      visible: _ctx.showDeleteConfirmDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDeleteConfirmDialog) = $event)),
      onDelete: _ctx.deleteDocument
    }, null, 8, ["visible", "onDelete"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentArchive.documents, (document) => {
      return (_openBlock(), _createElementBlock("div", {
        dense: "",
        flat: "",
        key: document.uuid,
        class: "row q-pl-sm q-pr-sm q-mb-sm items-center rounded-borders",
        "max-width": "400px"
      }, [
        (_ctx.deleteOption)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              onClick: _withModifiers(($event: any) => {
                    _ctx.documentToDelete = document;
                    _ctx.showDeleteConfirmDialog = true;
                }, ["prevent"]),
              icon: "delete",
              color: "negative",
              size: "sm",
              padding: "xs"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.downloadOption)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              onClick: _withModifiers(($event: any) => (_ctx.downloadDocument(document.url)), ["prevent"]),
              icon: "file_download",
              color: "secondary",
              size: "sm",
              padding: "xs",
              class: _normalizeClass({ 'q-ml-sm': _ctx.deleteOption })
            }, null, 8, ["onClick", "class"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, _toDisplayString(document.name), 1)
      ]))
    }), 128))
  ]))
}