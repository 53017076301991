
import Commission from '@/api/models/Commission';
import User from '@/api/models/User';
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    Ref,
    ref,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { selectAtLeastOne } from '@/validation/validation';
import QSelect from 'quasar/src/components/select/QSelect.js';;

export default defineComponent({
    name: 'CommissionsSelector',
    props: {
        modelValue: {
            type: Array as PropType<Commission[] | Commission>,
            required: true,
        },
        singleSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        userCommissions: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        isValid: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['update:modelValue', 'update:isValid'],
    setup(props, { emit }) {
        const commissions: Ref<Commission[]> = ref([]);
        const loadingCommissions: Ref<boolean> = ref(true);

        const loadCommissions = async () => {
            if (props.userCommissions) {
                const store = useStore();
                const user: User = store.getters.user;
                commissions.value = user.commissions as Commission[];
            } else {
                commissions.value = await Commission.all();
            }
            loadingCommissions.value = false;
        };

        const commissionsModel: WritableComputedRef<Commission[] | Commission> = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });

        const selectorRef = ref<QSelect | null>(null);
        const validateSelector = async () => {
            await nextTick();
            if (!props.required) {
                emit('update:isValid', true);
            }

            if (selectorRef.value !== null) {
                const selector = selectorRef.value as QSelect;
                emit('update:isValid', selector.validate() as boolean);
            }
        };

        onMounted(loadCommissions);

        const { t } = useI18n();

        return {
            t,
            loadingCommissions,
            commissions,
            commissionsModel,
            selectAtLeastOne,
            selectorRef,
            validateSelector,
        };
    },
});
