
import { defineComponent, PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import TranslatableText from '@/api/models/TranslatableText';

export default defineComponent({
    name: 'TranslatableContent',
    emits: ['update:modelValue'],
    props: {
        text: {
            type: Object as PropType<TranslatableText>,
        },
        truncate: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const store = useStore();

        const displayLanguage = computed(() => store.getters.displayLanguage);

        const textDisplay = computed(() => {
            if (displayLanguage.value === 'nl') return props.text?.nl || props.text?.fr;
            else if (displayLanguage.value === 'fr') return props.text?.fr || props.text?.nl;
            else return '';
        });

        return { t, textDisplay };
    },
});
