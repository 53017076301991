
import Villa from '@/api/models/Villa';
import { computed, defineComponent, onMounted, PropType, Ref, ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'VillasSelector',
    props: {
        modelValue: {
            type: Array as PropType<Villa[]>,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const villas: Ref<Villa[]> = ref([]);
        const loadingVillas: Ref<boolean> = ref(true);

        const loadVillas = async () => {
            villas.value = await Villa.all();
            loadingVillas.value = false;
        };

        const villasModel = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        });

        onMounted(loadVillas);

        const { t } = useI18n();

        return {
            t,
            loadingVillas,
            villas,
            villasModel,
        };
    },
});
