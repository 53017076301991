import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "col-grow" }
const _hoisted_3 = { class: "row text-body1" }
const _hoisted_4 = { class: "row text-caption items-center justify-start no-wrap ellipsis" }
const _hoisted_5 = {
  key: 0,
  class: "row text-caption items-center justify-start no-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_create = _resolveComponent("user-create")!
  const _component_user_details = _resolveComponent("user-details")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_user_create, {
      visible: _ctx.showUserCreateDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showUserCreateDialog) = $event)),
      onReload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadUsers({ pagination: _ctx.pagination })))
    }, null, 8, ["visible"]),
    _createVNode(_component_user_details, {
      visible: _ctx.showUserDetailsDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showUserDetailsDialog) = $event)),
      userSlug: _ctx.userSlug,
      "onUpdate:userSlug": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userSlug) = $event)),
      onReload: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadUsers({ pagination: _ctx.pagination })))
    }, null, 8, ["visible", "userSlug"]),
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.users,
      "onUpdate:objects": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.users) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      "onUpdate:loading": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.loading) = $event)),
      title: _ctx.t('Users'),
      createTitle: _ctx.t('Create user'),
      onReload: _cache[9] || (_cache[9] = ($event: any) => (_ctx.loadUsers({ pagination: _ctx.pagination }))),
      onCreate: _ctx.createUserButtonPressed,
      onDetail: _ctx.userDetailButtonPressed
    }, {
      row: _withCtx((props) => [
        _createElementVNode("img", {
          src: props.object.avatar,
          height: "80",
          width: "80",
          class: "q-mr-md",
          style: {"object-fit":"cover"}
        }, null, 8, _hoisted_1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(props.object.name), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_icon, {
              name: "email",
              class: "q-mr-xs"
            }),
            _createElementVNode("span", null, _toDisplayString(props.object.email), 1)
          ]),
          (props.object.villas && props.object.villas.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_q_icon, {
                  name: "cottage",
                  class: "q-mr-xs"
                }),
                _createElementVNode("span", null, _toDisplayString(props.object.villas.map((villa) => villa.number).join(', ')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle", "onCreate", "onDetail"])
  ], 64))
}