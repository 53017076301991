
import User from '@/api/models/User';
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import UserDetails from '@/views/User/UserDetails.vue';
import UserCreate from '@/views/User/UserCreate.vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: { OverviewPageTableLayout, UserDetails, UserCreate },
    name: 'UserOverview',
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const loading = ref(true);
        const showUserDetailsDialog = ref(false);
        const showUserCreateDialog = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const users: Ref<User[]> = ref([]);
        const loadUsers = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;

            users.value = [];

            const response = await User.all({
                search: searchFilter.value || undefined,
                pageNumber: props.pagination.page,
            });

            users.value = response.users;

            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loading.value = false;
        };

        const searchFilter = ref(null);

        const search = () => {
            loadUsers({ pagination: pagination.value });
        };

        const userSlug: Ref<string | null> = ref(null);
        const openUserDetailsDialog = (slug: string) => {
            userSlug.value = slug;
            showUserDetailsDialog.value = true;
        };

        watch(
            () => route.params.user,
            () => {
                if (route.params.user && route.params.user !== '') {
                    openUserDetailsDialog(route.params.user as string);
                } else {
                    userSlug.value = null;
                }
            }
        );

        watch(
            () => showUserDetailsDialog.value,
            () => {
                if (!showUserDetailsDialog.value) {
                    router.push({ name: 'user.overview' });
                }
            }
        );

        onBeforeMount(async () => {
            loadUsers({ pagination: pagination.value });
            if (route.params.user && route.params.user !== '') {
                openUserDetailsDialog(route.params.user as string);
            } else {
                userSlug.value = null;
            }
        });

        const createUserButtonPressed = () => {
            userSlug.value = null;
            showUserCreateDialog.value = true;
        };

        const userDetailButtonPressed = ({ slug }: { slug: string }) => {
            router.push({ name: 'user.overview', params: { user: slug } });
        };

        return {
            t,
            userSlug,
            showUserDetailsDialog,
            openUserDetailsDialog,
            users,
            loadUsers,
            pagination,
            loading,
            pagesNumber,
            formatDate,
            search,
            searchFilter,
            showUserCreateDialog,
            createUserButtonPressed,
            userDetailButtonPressed,
        };
    },
});
