import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row justify-end q-pb-md" }
const _hoisted_2 = { class: "row q-col-gutter-md" }
const _hoisted_3 = { class: "col-xs-6 text-h5" }
const _hoisted_4 = { class: "row justify-end col-xs-6" }
const _hoisted_5 = { class: "row q-mt-md q-mb-lg" }
const _hoisted_6 = { class: "absolute-bottom text-subtitle2 text-center" }
const _hoisted_7 = { class: "text-subtitle1 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_commission_details = _resolveComponent("commission-details")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_commission_details, {
        modelValue: _ctx.showDetailsDialog,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDetailsDialog) = $event)),
        commission: _ctx.commission,
        onReloadCommissions: _ctx.loadCommissions
      }, null, 8, ["modelValue", "commission", "onReloadCommissions"]),
      _createVNode(_component_spinner, {
        modelValue: _ctx.loading,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.user.is_admin)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              color: "secondary",
              label: _ctx.t('Create commission'),
              disable: _ctx.loading,
              onClick: _ctx.showDetailsDialogAsCreate,
              class: _normalizeClass({
                    'full-width': this.$q.screen.width < 480,
                })
            }, null, 8, ["label", "disable", "onClick", "class"]))
          : _createCommentVNode("", true)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commissions, (commission) => {
        return (_openBlock(), _createElementBlock("div", {
          key: commission.uuid
        }, [
          (commission.users.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(commission.name), 1),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.user.is_admin)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.openCommissionUpdateDialog(commission)),
                          color: "primary",
                          icon: "edit",
                          flat: "",
                          round: ""
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commission.users, (user) => {
                    return (_openBlock(), _createBlock(_component_q_card, {
                      flat: "",
                      class: "q-pr-md q-mb-md col-xs-12 col-sm-6 col-md-4 col-lg-3",
                      key: user.uuid
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_img, {
                          src: user.avatar,
                          fit: "cover",
                          style: {"height":"300px"}
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, _toDisplayString(user.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["src"]),
                        (user.title)
                          ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, _toDisplayString(user.title), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ], 64))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}