
import DocumentArchive from '@/api/models/DocumentArchive';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import Documents from '@/components/Documents.vue';
import {
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    computed,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDatetime, formatDate } from '@/util/formatters';

export default defineComponent({
    name: 'DocumentArchiveDetails',
    components: { CrudDialog, Documents },
    props: {
        /**
         * If the dialog is opened.
         */
        visible: {
            default: false,
            type: Boolean,
        },
        /**
         * Slug of the documentarchive.
         */
        documentArchiveSlug: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showDocumentArchiveDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const documentarchive: Ref<DocumentArchive | null> = ref(null);

        const loadDocumentArchive = async () => {
            if (props.documentArchiveSlug) {
                documentarchive.value = null;
                documentarchive.value = await DocumentArchive.get(
                    props.documentArchiveSlug as string
                );
            }
        };

        onBeforeMount(async () => await loadDocumentArchive());

        watch(
            () => props.documentArchiveSlug,
            async () => await loadDocumentArchive()
        );

        return {
            t,
            showDocumentArchiveDialog,
            documentarchive,
            formatDatetime,
            formatDate,
        };
    },
});
