import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_commissions_selector = _resolveComponent("commissions-selector")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    medium: "",
    languageSelect: "",
    language: _ctx.language,
    "onUpdate:language": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.language) = $event)),
    visible: _ctx.visible,
    title: _ctx.t('Create new announcement'),
    errors: _ctx.errors,
    "with-save": "",
    isProcessing: _ctx.isProcessing,
    onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onSave: _ctx.saveAnnouncement
  }, {
    default: _withCtx(() => [
      _createVNode(_component_translatable_input, {
        language: _ctx.language,
        modelValue: _ctx.newAnnouncement.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAnnouncement.title) = $event)),
        "is-valid": _ctx.validTitle,
        "onUpdate:is-valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validTitle) = $event)),
        title: _ctx.t('Title'),
        type: "text"
      }, null, 8, ["language", "modelValue", "is-valid", "title"]),
      _createVNode(_component_translatable_input, {
        language: _ctx.language,
        modelValue: _ctx.newAnnouncement.contents,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAnnouncement.contents) = $event)),
        "is-valid": _ctx.validContents,
        "onUpdate:is-valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validContents) = $event)),
        title: _ctx.t('Contents'),
        type: "textarea"
      }, null, 8, ["language", "modelValue", "is-valid", "title"]),
      _createVNode(_component_commissions_selector, {
        modelValue: _ctx.selectedCommission,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCommission) = $event)),
        "is-valid": _ctx.validCommissionSelector,
        "onUpdate:is-valid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.validCommissionSelector) = $event)),
        "single-select": "",
        "user-commissions": !_ctx.user?.is_admin,
        required: ""
      }, null, 8, ["modelValue", "is-valid", "user-commissions"]),
      _createVNode(_component_file_selector, {
        modelValue: _ctx.documents,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.documents) = $event)),
        label: _ctx.t('Upload documents (optional)'),
        class: "q-mt-md"
      }, null, 8, ["modelValue", "label"])
    ]),
    _: 1
  }, 8, ["language", "visible", "title", "errors", "isProcessing", "onSave"]))
}