import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-display-card-color-section text-center text-h6 text-primary" }
const _hoisted_2 = { class: "row justify-center q-pt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "q-ma-md",
    flat: "",
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.user.name), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_img, {
          src: _ctx.user.avatar,
          "img-style": { borderRadius: '50%' },
          ratio: "1",
          width: "50%"
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_q_card_section, { class: "text-body1" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Owner of house(s)')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.villas.map((villa) => villa.number).join(', ')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Home address')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(`${_ctx.user.address.street} ${_ctx.user.address.number} ${_ctx.user.address.postal_code} ${_ctx.user.address.city}`), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Telephone number')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.phone_number), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Mobile phone number')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.mobile_phone_number), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Hobbies')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.hobbies), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Email')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.user.email), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-subtitle2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Date of birth')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(_ctx.user.date_of_birth)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}