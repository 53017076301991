import api, { PaginatedApiMeta } from '../core/api';
import { CommissionData } from './Commission';
import { FileData } from './File';
import TranslatableText, { TranslatableTextData } from './TranslatableText';

export interface DocumentArchiveData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    meeting_date?: string;
    created_at?: string;
    commission?: CommissionData;
    documents?: FileData[];
}

export default class DocumentArchive implements DocumentArchiveData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    meeting_date?: string;
    created_at?: string;
    commission?: CommissionData;
    documents?: FileData[];

    constructor(data: DocumentArchiveData) {
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = new TranslatableText(data.title ?? {});
        this.meeting_date = data.meeting_date;
        this.created_at = data.created_at;
        this.commission = data.commission;
        this.documents = data.documents;
    }

    static async all(
        config: {
            search?: string;
            pageNumber?: number;
        } = {}
    ): Promise<{ documentarchives: DocumentArchive[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<DocumentArchiveData[]>('/document-archives', {
            search: config.search || null,
            page: config.pageNumber,
        });

        return {
            documentarchives: response.data.map(
                (documentarchive) => new DocumentArchive(documentarchive)
            ),
            meta: response.meta,
        };
    }

    static async get(slug: string): Promise<DocumentArchive> {
        const response = await api.get<DocumentArchive>(`/document-archives/${slug}`);
        return new DocumentArchive(response.data);
    }

    async save(documents: File[]): Promise<DocumentArchive> {
        const fd = new FormData();

        fd.append('title', TranslatableText.getJson(this.title));
        if (this.meeting_date) {
            fd.append('meeting_date', this.meeting_date as string);
        }
        fd.append('commission', this.commission?.uuid as string);
        for (const document of documents) {
            fd.append('documents[]', document);
        }

        const url =
            this.uuid == null ? '/document-archives' : `/document-archives/${this.getSlug()}`;
        const response = await api.post<DocumentArchive>(
            url,
            fd,
            {},
            { 'Content-Type': 'multipart/form-data' }
        );
        return new DocumentArchive(response.data);
    }

    async delete(): Promise<void> {
        await api.delete(`/document-archives/${this.getSlug()}`);
    }

    async deleteDocument(document: FileData): Promise<DocumentArchive> {
        const response = await api.delete<DocumentArchive>(
            `/document-archives/${this.getSlug()}/documents/${document.uuid}`
        );
        return new DocumentArchive(response.data);
    }

    getSlug(): string | undefined {
        return this.slug?.nl || this.slug?.fr;
    }
}
