import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!

  return (_openBlock(), _createBlock(_component_q_file, {
    ref: "fileSelectorRef",
    modelValue: _ctx.files,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
      _ctx.validateSelector
    ],
    label: _ctx.label,
    "max-files": _ctx.single ? 1 : _ctx.maxFiles,
    multiple: !_ctx.single,
    accept: _ctx.images ? '.jpg, .jpeg, .png' : '',
    rules: _ctx.required ? [_ctx.selectAtLeastOne(_ctx.images ? 'photo' : 'document')] : [],
    filled: "",
    counter: "",
    "stack-label": this.$q.screen.width < 512
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "attach_file" })
    ]),
    append: _withCtx(() => [
      _renderSlot(_ctx.$slots, "append")
    ]),
    _: 3
  }, 8, ["modelValue", "label", "max-files", "multiple", "accept", "rules", "onUpdate:modelValue", "stack-label"]))
}