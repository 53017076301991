import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    language: _ctx.language,
    "onUpdate:language": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.language) = $event)),
    visible: _ctx.visible,
    title: _ctx.t('Upload new document'),
    errors: _ctx.errors,
    "is-processing": _ctx.isProcessing,
    medium: "",
    languageSelect: "",
    "with-save": "",
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onSave: _ctx.saveInformationDocument
  }, {
    default: _withCtx(() => [
      _createVNode(_component_translatable_input, {
        modelValue: _ctx.newInformationDocument.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newInformationDocument.title) = $event)),
        "is-valid": _ctx.isValidTitle,
        "onUpdate:is-valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValidTitle) = $event)),
        language: _ctx.language,
        title: _ctx.t('Title'),
        type: "text"
      }, null, 8, ["modelValue", "is-valid", "language", "title"]),
      _createVNode(_component_file_selector, {
        modelValue: _ctx.document,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.document) = $event)),
        "is-valid": _ctx.isValidDocumentSelector,
        "onUpdate:is-valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isValidDocumentSelector) = $event)),
        single: "",
        label: _ctx.t('Document'),
        required: "",
        class: "q-mt-md"
      }, null, 8, ["modelValue", "is-valid", "label"])
    ]),
    _: 1
  }, 8, ["language", "visible", "title", "errors", "is-processing", "onSave"]))
}