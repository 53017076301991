import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_gears = _resolveComponent("q-spinner-gears")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createBlock(_component_q_inner_loading, { showing: _ctx.isProcessing }, {
    default: _withCtx(() => [
      _createVNode(_component_q_spinner_gears, {
        size: "50px",
        color: "primary"
      }),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["showing"]))
}