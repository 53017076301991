
import { defineComponent, WritableComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'DeleteConfirmDialog',
    emits: ['update:visible', 'close', 'save', 'delete'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const show: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        return { t, show };
    },
});
