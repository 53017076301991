import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = { class: "row items-start justify-center q-mt-lg q-col-gutter-lg" }
const _hoisted_3 = { class: "col-xs-12 col-sm-6 avatar row q-mt-lg q-mb-md column items-center" }
const _hoisted_4 = { class: "col-xs-12" }
const _hoisted_5 = { class: "col-xs-12 col-sm-6 row" }
const _hoisted_6 = { class: "text-h6 col-xs-12" }
const _hoisted_7 = { class: "col-xs-12 row justify-end" }
const _hoisted_8 = { class: "col-xs-12 col-md-6" }
const _hoisted_9 = { class: "text-h6 col-xs-12" }
const _hoisted_10 = { class: "col-xs-12 col-md-6" }
const _hoisted_11 = { class: "text-h6 col-xs-12" }
const _hoisted_12 = { class: "row col-xs-12 q-col-gutter-sm" }
const _hoisted_13 = { class: "row col-xs-12 q-col-gutter-sm" }
const _hoisted_14 = { class: "col-xs-12 offset-md-6 col-md-6 q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_errors = _resolveComponent("errors")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_date_selector = _resolveComponent("date-selector")!
  const _component_language_toggle = _resolveComponent("language-toggle")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_spinner = _resolveComponent("spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('Account information')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_errors, {
        class: "col-xs-12",
        errors: _ctx.submitPersonalInformationErrors
      }, null, 8, ["errors"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_img, {
          src: _ctx.user.avatar,
          "img-style": { borderRadius: '50%' },
          ratio: "1",
          style: {"max-width":"250px"}
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_errors, {
            errors: _ctx.uploadNewAvatarErrors,
            style: {"min-width":"300px"},
            class: "q-mt-lg"
          }, null, 8, ["errors"]),
          _createVNode(_component_file_selector, {
            modelValue: _ctx.newAvatar,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAvatar) = $event)),
            label: _ctx.t('New avatar'),
            images: "",
            class: "q-mt-md",
            single: ""
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_btn, {
                icon: "upload",
                color: "secondary",
                onClick: _ctx.uploadNewAvatar,
                unelevated: ""
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["modelValue", "label"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('Change password')), 1),
        _createVNode(_component_errors, {
          class: "col-xs-12",
          errors: _ctx.submitNewPasswordErrors
        }, null, 8, ["errors"]),
        _createVNode(_component_q_form, {
          ref: "passwordResetForm",
          onSubmit: _ctx.submitNewPassword,
          class: "col-xs-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              modelValue: _ctx.password.current,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password.current) = $event)),
              label: _ctx.t('Current password'),
              rules: [_ctx.required('Current password')],
              class: "col-xs-12",
              type: "password"
            }, null, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: _ctx.password.new,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password.new) = $event)),
              label: _ctx.t('New password'),
              rules: [_ctx.required('New password'), _ctx.strongPassword],
              class: "col-xs-12",
              type: "password"
            }, null, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: _ctx.password.confirmation,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password.confirmation) = $event)),
              label: _ctx.t('Password confirmation'),
              rules: [_ctx.required('Password confirmation'), _ctx.strongPassword, _ctx.equalToNewPassword],
              class: "col-xs-12",
              type: "password"
            }, null, 8, ["modelValue", "label", "rules"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_q_btn, {
                label: _ctx.t('Change'),
                class: "q-mt-md full-width",
                color: "secondary",
                type: "submit"
              }, null, 8, ["label"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ]),
    _createVNode(_component_q_separator, { spaced: "50px" }),
    _createVNode(_component_q_form, {
      class: "row q-col-gutter-lg",
      onSubmit: _ctx.submitPersonalInformation
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('Personal information')), 1),
          _createVNode(_component_q_input, {
            modelValue: _ctx.user.name,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.name) = $event)),
            label: _ctx.t('Name'),
            rules: [_ctx.required('Name'), _ctx.maxLength('Name', 255)],
            class: "col-xs-12"
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            modelValue: _ctx.user.email,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.email) = $event)),
            label: _ctx.t('Email'),
            rules: [_ctx.required('Email'), _ctx.email],
            class: "col-xs-12"
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            modelValue: _ctx.user.hobbies,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.hobbies) = $event)),
            label: _ctx.t('Hobbies'),
            rules: [_ctx.maxLength('Hobbies', 255)],
            class: "col-xs-12"
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            modelValue: _ctx.user.mobile_phone_number,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.mobile_phone_number) = $event)),
            label: _ctx.t('Mobile phone number'),
            rules: [_ctx.maxLength('Mobile phone number', 255)],
            class: "col-xs-12"
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_q_input, {
            modelValue: _ctx.user.phone_number,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.phone_number) = $event)),
            label: _ctx.t('Phone number'),
            rules: [_ctx.maxLength('Phone number', 255)],
            class: "col-xs-12"
          }, null, 8, ["modelValue", "label", "rules"]),
          _createVNode(_component_date_selector, {
            modelValue: _ctx.user.date_of_birth,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.date_of_birth) = $event)),
            label: _ctx.t('Date of birth'),
            class: "col-xs-12",
            clearable: ""
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_language_toggle, {
            modelValue: _ctx.user.language,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.language) = $event)),
            class: "col-xs-12 q-mt-md"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_q_toggle, {
            modelValue: _ctx.user.receive_forum_post_notifications,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.receive_forum_post_notifications) = $event)),
            label: _ctx.t('Receive forum post notifications'),
            class: "col-xs-12 q-mt-md"
          }, null, 8, ["modelValue", "label"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('Home address')), 1),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_input, {
              modelValue: _ctx.user.address.street,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.address.street) = $event)),
              label: _ctx.t('Street'),
              rules: [_ctx.maxLength('Street', 255)],
              class: "col-xs-9"
            }, null, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: _ctx.user.address.number,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.user.address.number) = $event)),
              label: _ctx.t('Number'),
              rules: [_ctx.maxLength('Number', 255)],
              class: "col-xs-3"
            }, null, 8, ["modelValue", "label", "rules"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_q_input, {
              modelValue: _ctx.user.address.postal_code,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.user.address.postal_code) = $event)),
              label: _ctx.t('Postal code'),
              rules: [_ctx.maxLength('Postal code', 255)],
              class: "col-xs-12 col-md-6"
            }, null, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: _ctx.user.address.city,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.user.address.city) = $event)),
              label: _ctx.t('City'),
              rules: [_ctx.maxLength('City', 255)],
              class: "col-xs-12 col-md-6"
            }, null, 8, ["modelValue", "label", "rules"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_q_btn, {
            label: _ctx.t('Save'),
            color: "secondary",
            type: "submit",
            class: "full-width"
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _createVNode(_component_spinner, {
      modelValue: _ctx.isProcessing,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.isProcessing) = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}