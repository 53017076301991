import api, { PaginatedApiMeta } from '../core/api';
import ForumComment, { ForumCommentData } from './ForumComment';
import TranslatableText, { TranslatableTextData } from './TranslatableText';
import User, { UserData } from './User';

export interface ForumPostData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    contents?: TranslatableTextData;
    user?: UserData;
    views?: number;
    num_comments?: number;
    comments?: ForumCommentData[];
    created_at?: string;
}

export default class ForumPost implements ForumPostData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    contents?: TranslatableTextData;
    user?: User;
    views?: number;
    num_comments?: number;
    comments?: ForumComment[];
    created_at?: string;

    constructor(data: ForumPostData) {
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = new TranslatableText(data.title ?? {});
        this.contents = new TranslatableText(data.contents ?? {});
        this.user = new User(data.user ?? {});
        this.views = data.views;
        this.num_comments = data.num_comments;
        this.comments = data.comments
            ? data.comments.map((comment) => new ForumComment(comment))
            : [];
        this.created_at = data.created_at;
    }

    static async all(
        config: {
            search?: string;
            sortOnProperty?: string;
            ascending?: boolean;
            numberPerPage?: number;
            pageNumber?: number;
        } = {}
    ): Promise<{ forumPosts: ForumPost[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<ForumPostData[]>('/forum-posts', {
            search: config.search || null,
            sort: config.sortOnProperty || 'created_at',
            asc: config.ascending || false,
            number: config.numberPerPage,
            page: config.pageNumber,
        });

        return {
            forumPosts: response.data.map((fp) => new ForumPost(fp)),
            meta: response.meta,
        };
    }

    static async get(slug: string): Promise<ForumPost> {
        const response = await api.get<ForumPost>(`/forum-posts/${slug}`);
        return new ForumPost(response.data);
    }

    async save(): Promise<ForumPost> {
        const response = await api.post<ForumPost>('/forum-posts', {
            title: TranslatableText.getJson(this.title),
            contents: TranslatableText.getJson(this.contents),
        });
        return new ForumPost(response.data);
    }

    async addComment(comment: TranslatableText): Promise<ForumPost> {
        const response = await api.post<ForumPost>(`/forum-posts/${this.getSlug()}/add-comment`, {
            comment: TranslatableText.getJson(comment),
        });
        return new ForumPost(response.data);
    }

    async delete(): Promise<void> {
        await api.delete(`/forum-posts/${this.getSlug()}`);
    }

    async deleteComment(commentUuid: string): Promise<void> {
        await api.delete(`/forum-posts/${this.getSlug()}/comments/${commentUuid}`);
    }

    getSlug(): string | undefined {
        return this.slug?.nl || this.slug?.fr;
    }

    hasTitleForAtLeastOneLanguage(): boolean {
        return this.title?.nl !== '' || this.title?.fr !== '';
    }
}
