import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "column justify-between full-height" }
const _hoisted_3 = {
  class: "text-h6",
  style: {"width":"100%"}
}
const _hoisted_4 = { class: "row text-subtitle2 items-center justify-start" }
const _hoisted_5 = { class: "row text-subtitle2 items-center justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_photo_album_details = _resolveComponent("photo-album-details")!
  const _component_photo_album_create = _resolveComponent("photo-album-create")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_photo_album_details, {
      photoAlbumSlug: _ctx.photoAlbumSlug,
      "onUpdate:photoAlbumSlug": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.photoAlbumSlug) = $event)),
      visible: _ctx.showPhotoAlbumDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPhotoAlbumDialog) = $event))
    }, null, 8, ["photoAlbumSlug", "visible"]),
    _createVNode(_component_photo_album_create, {
      modelValue: _ctx.showCreatePhotoAlbumDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCreatePhotoAlbumDialog) = $event)),
      onCreated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadPhotoAlbums({ pagination: _ctx.pagination })))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_overview_page_table_layout, {
      grid: "",
      objects: _ctx.photoAlbums,
      "onUpdate:objects": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.photoAlbums) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      title: _ctx.t('Photo albums'),
      createTitle: _ctx.t('Create album'),
      onReload: _cache[7] || (_cache[7] = ($event: any) => (_ctx.loadPhotoAlbums({ pagination: _ctx.pagination }))),
      onCreate: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showCreatePhotoAlbumDialog = true)),
      onDetail: _cache[9] || (_cache[9] = 
            ({ slug }) =>
                _ctx.$router.push({
                    name: 'photoalbum.overview',
                    params: { photoalbum: slug },
                })
        )
    }, {
      item: _withCtx((props) => [
        _createElementVNode("img", {
          src: props.object.photo?.url,
          height: "200",
          class: "q-mr-md",
          style: {"object-fit":"cover"}
        }, null, 8, _hoisted_1),
        _createVNode(_component_q_card_section, { style: {"height":"calc(100% - 200px)"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_translatable_content, {
                  text: props.object.title
                }, null, 8, ["text"])
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_icon, {
                    name: "person",
                    class: "q-mr-xs"
                  }),
                  _createElementVNode("span", null, _toDisplayString(props.object.created_by), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_icon, {
                    name: "event",
                    class: "q-mr-xs"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(props.object.created_at)), 1)
                ])
              ])
            ])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle"])
  ], 64))
}