
import Announcement from '@/api/models/Announcement';
import {
    computed,
    ComputedRef,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import OverviewPageTableLayout from '@/components/Layout/OverviewPageTableLayout.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import CommissionsSelector from '@/components/Selectors/CommissionsSelector.vue';
import AnnouncementDetails from '@/views/Announcement/AnnouncementDetails.vue';
import AnnouncementUpdate from '@/views/Announcement/AnnouncementUpdate.vue';
import Commission from '@/api/models/Commission';
import { useRoute, useRouter } from 'vue-router';
import AnnouncementCreate from '@/views/Announcement/AnnouncementCreate.vue';
import { useStore } from 'vuex';

export default defineComponent({
    components: {
        OverviewPageTableLayout,
        CommissionsSelector,
        AnnouncementDetails,
        AnnouncementCreate,
        AnnouncementUpdate,
        TranslatableContent,
    },
    name: 'AnnouncementOverview',
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();

        const loading = ref(true);
        const announcementSlug: Ref<string | null> = ref(null);
        const announcementUpdateSlug: Ref<string | null> = ref(null);
        const showAnnouncementDialog = ref(false);
        const showAnnouncementCreateDialog = ref(false);
        const showAnnouncementUpdateDialog = ref(false);

        const pagination: Ref<QPaginationMeta> = ref({
            sortBy: 'created_at',
            descending: true,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 0,
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(pagination.value.rowsNumber / pagination.value.rowsPerPage)
        );

        const announcements: Ref<Announcement[]> = ref([]);
        const loadAnnouncements = async (props: { pagination: QPaginationMeta }) => {
            loading.value = true;

            announcements.value = [];

            const response = await Announcement.all({
                search: searchFilter.value || undefined,
                commissions: commissions.value,
                numberPerPage: props.pagination.rowsPerPage,
                pageNumber: props.pagination.page,
            });

            announcements.value = response.announcements;

            pagination.value.rowsNumber = response.meta.total;
            pagination.value.page = response.meta.current_page;
            pagination.value.rowsPerPage = response.meta.per_page;
            pagination.value.sortBy = props.pagination.sortBy;
            pagination.value.descending = props.pagination.descending;

            loading.value = false;
        };

        const searchFilter = ref(null);

        const commissions: Ref<Commission[] | null> = ref(null);

        const search = () => {
            loadAnnouncements({ pagination: pagination.value });
        };

        const openAnnouncementDetailDialog = (slug: string) => {
            announcementSlug.value = slug;
            showAnnouncementDialog.value = true;
        };

        watch(
            () => commissions.value,
            () => loadAnnouncements({ pagination: pagination.value })
        );

        watch(
            () => route.params.announcement,
            () => {
                if (route.params.announcement && route.params.announcement !== '') {
                    openAnnouncementDetailDialog(route.params.announcement as string);
                }
            }
        );

        watch(
            () => showAnnouncementDialog.value,
            () => {
                if (!showAnnouncementDialog.value) {
                    router.push({ name: 'announcement.overview' });
                }
            }
        );

        const store = useStore();
        const user = computed(() => store.getters.user);
        onBeforeMount(async () => {
            loadAnnouncements({ pagination: pagination.value });
            if (route.params.announcement && route.params.announcement !== '') {
                openAnnouncementDetailDialog(route.params.announcement as string);
            }
        });

        return {
            t,
            announcementSlug,
            announcementUpdateSlug,
            showAnnouncementDialog,
            openAnnouncementDetailDialog,
            announcements,
            loadAnnouncements,
            pagination,
            loading,
            pagesNumber,
            formatDate,
            search,
            searchFilter,
            commissions,
            showAnnouncementCreateDialog,
            showAnnouncementUpdateDialog,
            user,
        };
    },
});
