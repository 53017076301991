
import { ApiAuthorizationError, ApiValidationError } from '@/api/core/api';
import Announcement from '@/api/models/Announcement';
import { CommissionData } from '@/api/models/Commission';
import {
    computed,
    defineComponent,
    Ref,
    ref,
    watch,
    WritableComputedRef,
    onBeforeMount,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import CommissionsSelector from '@/components/Selectors/CommissionsSelector.vue';
import CrudDialog from '../../components/Interaction/CrudDialog.vue';
import FileSelector from '../../components/Interaction/FileSelector.vue';
import Documents from '@/components/Documents.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import { missingTranslationNotification } from '@/composables/notifications';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: { CrudDialog, CommissionsSelector, FileSelector, Documents, TranslatableInput },
    name: 'AnnouncementUpdate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        /**
         * Slug of the announcement.
         */
        announcementSlug: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue', 'reload'],
    setup(props, { emit }) {
        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);
        const { t } = useI18n();

        const selectedCommission: Ref<CommissionData | null> = ref(null);
        const documents: Ref<File[]> = ref([]);
        const announcement: Ref<Announcement | null> = ref(null);
        const language: Ref<string> = ref('nl');

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        const numAlreadyUploadedFiles = computed(() => {
            if (announcement.value && announcement.value.document_archive) {
                return announcement.value.document_archive.documents?.length || 0;
            }
            return 0;
        });

        const loadAnnouncement = async () => {
            errors.value = [];
            announcement.value = null;
            documents.value = [];
            if (props.announcementSlug) {
                announcement.value = await Announcement.get(props.announcementSlug);
                selectedCommission.value = announcement.value.commission || null;
            }
        };

        const validTitle = ref(true);
        const validContents = ref(true);
        const validCommissionSelector = ref(true);
        const validForm = computed(
            () => validTitle.value && validContents.value && validCommissionSelector.value
        );

        const $q = useQuasar();
        const saveAnnouncement = async () => {
            if (validForm.value) {
                if (announcement.value === null) return;
                isProcessing.value = true;
                try {
                    await announcement.value.save(documents.value);
                    visible.value = false;
                    emit('reload');
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else if (e instanceof ApiAuthorizationError) {
                        errors.value = [
                            t('Only members of commissions can update their own announcements.'),
                        ];
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else {
                if (!validTitle.value || !validContents.value) {
                    $q.notify(missingTranslationNotification);
                }
            }
        };

        const deleteAnnouncement = async () => {
            if (announcement.value !== null) {
                isProcessing.value = true;
                await announcement.value.delete();
                emit('reload');
                isProcessing.value = false;
                visible.value = false;
            }
        };

        watch(
            () => props.announcementSlug,
            async () => await loadAnnouncement()
        );

        watch(selectedCommission, (updatedCommission: CommissionData | null) => {
            if (announcement === null) return;
            announcement.value!.commission = updatedCommission || undefined;
        });

        onBeforeMount(async () => {
            await loadAnnouncement();
            validCommissionSelector.value = announcement.value?.commission !== null;
        });

        return {
            visible,
            errors,
            isProcessing,
            t,
            announcement,
            selectedCommission,
            documents,
            numAlreadyUploadedFiles,
            deleteAnnouncement,
            saveAnnouncement,
            loadAnnouncement,
            language,
            validTitle,
            validContents,
            validCommissionSelector,
        };
    },
});
