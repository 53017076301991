
import ForumPost from '@/api/models/ForumPost';
import { defineComponent, onBeforeMount, ref, Ref, computed } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { formatDatetime } from '@/util/formatters';
import ForumCommentDetails from '@/views/Forum/ForumCommentDetails.vue';
import Errors from '@/components/Notifications/Errors.vue';
import { ApiValidationError } from '@/api/core/api';
import Spinner from '@/components/Util/Spinner.vue';
import TranslatableText from '@/api/models/TranslatableText';
import { useStore } from 'vuex';
import DeleteConfirmDialog from '@/components/Interaction/DeleteConfirmDialog.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import LanguageSelector from '@/components/Selectors/LanguageSelector.vue';
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { commentForOneLanguageIsRequiredNotification } from '@/composables/notifications';

export default defineComponent({
    components: {
        ForumCommentDetails,
        LanguageSelector,
        TranslatableInput,
        TranslatableContent,
        DeleteConfirmDialog,
        Errors,
        Spinner,
    },
    name: 'ForumDetails',
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const user = computed(() => store.getters.user);
        const isAdmin = computed(() => store.getters.isAdmin);
        const loading = ref(true);
        const showDeleteForumPostConfirmDialog = ref(false);
        const forumPost: Ref<ForumPost | null> = ref(null);
        const errors: Ref<string[]> = ref([]);
        const postingNewComment = ref(false);
        const newCommentText = ref(new TranslatableText({}));

        const isValidComment = ref(false);
        const newCommentTextHasContents = computed(
            () => newCommentText.value?.nl !== '' || newCommentText.value?.fr !== ''
        );
        const isValid = computed(() => isValidComment && newCommentTextHasContents.value);
        const language: Ref<string> = ref('nl');

        onBeforeMount(async () => {
            forumPost.value = await ForumPost.get(route.params.post as string);
            loading.value = false;
        });

        const $q = useQuasar();
        const addNewComment = async () => {
            errors.value = [];

            if (isValid.value) {
                postingNewComment.value = true;
                try {
                    forumPost.value =
                        (await forumPost.value?.addComment(newCommentText.value)) || null;
                    newCommentText.value = new TranslatableText({});
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    postingNewComment.value = false;
                }
            } else if (!newCommentTextHasContents.value) {
                $q.notify(commentForOneLanguageIsRequiredNotification);
            }
        };

        const deletePost = async () => {
            if (forumPost.value !== null) {
                errors.value = [];
                loading.value = true;
                try {
                    await forumPost.value.delete();
                    router.push({ name: 'forum.overview' });
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    loading.value = false;
                }
            }
        };

        const deleteComment = async (commentUuid: string) => {
            if (forumPost.value !== null) {
                errors.value = [];
                loading.value = true;
                try {
                    await forumPost.value.deleteComment(commentUuid);
                    forumPost.value = await ForumPost.get(route.params.post as string);
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    loading.value = false;
                }
            }
        };

        return {
            t,
            user,
            isAdmin,
            forumPost,
            loading,
            language,
            formatDatetime,
            newCommentText,
            addNewComment,
            errors,
            postingNewComment,
            showDeleteForumPostConfirmDialog,
            deletePost,
            deleteComment,
        };
    },
});
