
import Villa from '@/api/models/Villa';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import UserDisplayCard from '@/components/UserDisplayCard.vue';
import {
    computed,
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: { CrudDialog, UserDisplayCard },
    name: 'VillaDetail',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        villaNumber: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (v: boolean) => {
                emit('update:modelValue', v);
            },
        });

        const loading = ref(false);
        const villa: Ref<Villa | null> = ref(null);
        const loadVilla = async () => {
            loading.value = true;
            villa.value = null;
            if (props.villaNumber && props.villaNumber !== '') {
                villa.value = await Villa.get(props.villaNumber);
                loading.value = false;
            }
        };

        const title = computed(() => {
            if (villa.value && villa.value.users) {
                if (villa.value.users?.length > 1)
                    return `${t('Villa')} ${villa.value.number} - ${t('Owners')}`;
                else return `${t('Villa')} ${villa.value.number} - ${t('Owner')}`;
            } else return null;
        });

        onBeforeMount(async () => await loadVilla());
        watch(
            () => props.villaNumber,
            async () => await loadVilla()
        );

        return {
            t,
            title,
            visible,
            villa,
            loading,
        };
    },
});
