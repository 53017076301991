import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_commissions_selector = _resolveComponent("commissions-selector")!
  const _component_documents = _resolveComponent("documents")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    medium: "",
    languageSelect: "",
    language: _ctx.language,
    "onUpdate:language": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.language) = $event)),
    visible: _ctx.visible,
    title: _ctx.t('Update announcement'),
    errors: _ctx.errors,
    "with-save": "",
    "with-delete": "",
    isProcessing: _ctx.isProcessing,
    onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onSave: _ctx.saveAnnouncement,
    onDelete: _ctx.deleteAnnouncement
  }, {
    default: _withCtx(() => [
      (!_ctx.announcement)
        ? (_openBlock(), _createBlock(_component_q_skeleton, {
            key: 0,
            type: "textarea",
            height: "300px"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_translatable_input, {
              language: _ctx.language,
              modelValue: _ctx.announcement.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.announcement.title) = $event)),
              "is-valid": _ctx.validTitle,
              "onUpdate:is-valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.validTitle) = $event)),
              title: _ctx.t('Title'),
              type: "text"
            }, null, 8, ["language", "modelValue", "is-valid", "title"]),
            _createVNode(_component_translatable_input, {
              language: _ctx.language,
              modelValue: _ctx.announcement.contents,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.announcement.contents) = $event)),
              "is-valid": _ctx.validContent,
              "onUpdate:is-valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.validContent) = $event)),
              title: _ctx.t('Contents'),
              type: "textarea"
            }, null, 8, ["language", "modelValue", "is-valid", "title"]),
            _createVNode(_component_commissions_selector, {
              modelValue: _ctx.selectedCommission,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedCommission) = $event)),
              "is-valid": _ctx.validCommissionSelector,
              "onUpdate:is-valid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.validCommissionSelector) = $event)),
              required: "",
              "single-select": "",
              "user-commissions": ""
            }, null, 8, ["modelValue", "is-valid"]),
            (_ctx.announcement.document_archive)
              ? (_openBlock(), _createBlock(_component_documents, {
                  key: 0,
                  class: "q-mt-md",
                  onDeleted: _cache[6] || (_cache[6] = 
                        ({ documentArchive }) => (_ctx.announcement.document_archive = documentArchive)
                    ),
                  deleteOption: "",
                  documentArchive: _ctx.announcement.document_archive
                }, null, 8, ["documentArchive"]))
              : _createCommentVNode("", true),
            _createVNode(_component_file_selector, {
              modelValue: _ctx.documents,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.documents) = $event)),
              "max-files": 20 - _ctx.numAlreadyUploadedFiles,
              label: _ctx.t('Upload additional documents (optional)'),
              class: "q-mt-md"
            }, null, 8, ["modelValue", "max-files", "label"])
          ], 64))
    ]),
    _: 1
  }, 8, ["language", "visible", "title", "errors", "isProcessing", "onSave", "onDelete"]))
}