import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-h6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_btn, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = !_ctx.showDialog)),
      label: _ctx.t('Password forgotten'),
      class: "full-width",
      flat: "",
      color: "grey"
    }, null, 8, ["label"]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showDialog) = $event)),
      maximized: this.$q.screen.width < 492
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, { style: {"width":"480px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "row reverse-wrap justify-end" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('Reset password')), 1),
                _createVNode(_component_q_space),
                _withDirectives(_createVNode(_component_q_btn, {
                  icon: "close",
                  flat: "",
                  round: "",
                  dense: ""
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  type: "email",
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.error ? (_ctx.error = !_ctx.error) : false))
                  ],
                  label: _ctx.t('Email'),
                  clearable: "",
                  "bottom-slots": "",
                  "error-message": _ctx.errorMessage,
                  error: _ctx.error
                }, null, 8, ["modelValue", "label", "error-message", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_actions, {
              align: "between",
              vertical: this.$q.screen.width < 492
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  label: _ctx.t('Cancel'),
                  color: "grey",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialog = !_ctx.showDialog)),
                  flat: "",
                  class: _normalizeClass({
                            'full-width': this.$q.screen.width < 492,
                            'order-last': this.$q.screen.width < 492,
                        })
                }, null, 8, ["label", "class"]),
                _createVNode(_component_q_btn, {
                  label: _ctx.t('Reset password'),
                  color: "secondary",
                  onClick: _ctx.reset,
                  class: _normalizeClass({
                            'full-width': this.$q.screen.width < 492,
                            'order-first': this.$q.screen.width < 492,
                        })
                }, null, 8, ["label", "onClick", "class"])
              ]),
              _: 1
            }, 8, ["vertical"]),
            _createVNode(_component_spinner, {
              modelValue: _ctx.isProcessing,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isProcessing) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "maximized"])
  ]))
}