import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-notifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_banner = _resolveComponent("q-banner")!

  return (_ctx.reactiveErrors.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactiveErrors, (error, i) => {
          return (_openBlock(), _createBlock(_component_q_banner, {
            key: i,
            rounded: "",
            dense: "",
            class: "error-notification text-white bg-red q-mb-sm"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(error), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}