import api from '../core/api';
import User, { UserData } from './User';

export interface VillaData {
    uuid?: string;
    number?: number;
    users?: UserData[];
}

export default class Villa implements VillaData {
    uuid?: string;
    number?: number;
    users?: User[];

    constructor(data: VillaData) {
        this.uuid = data.uuid;
        this.number = data.number;
        this.users = data.users?.map((user) => new User(user));
    }

    static async all(): Promise<Villa[]> {
        const response = await api.get<Villa[]>('/villas');
        return response.data.map((villa) => new Villa(villa));
    }

    static async get(number: string): Promise<Villa> {
        const response = await api.get<Villa>(`/villas/${number}`);
        return new Villa(response.data);
    }
}
