import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = {
  key: 1,
  class: "text-italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_user_display_card = _resolveComponent("user-display-card")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.title,
    errors: [],
    "with-delete": false,
    "with-save": false,
    "is-processing": false,
    small: ""
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_skeleton, {
              class: "col-xs-12",
              type: "rect",
              height: "600px"
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.villa.users.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.villa.users, (user, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-12",
                      key: idx
                    }, [
                      _createVNode(_component_user_display_card, { user: user }, null, 8, ["user"])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t('No owners yet')), 1))
          ], 64))
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}