import api, { PaginatedApiMeta } from '../core/api';
import { FileData } from './File';
import TranslatableText, { TranslatableTextData } from './TranslatableText';

export interface InformationDocumentData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    created_at?: string;
    document?: FileData;
}

export default class InformationDocument implements InformationDocumentData {
    uuid?: string;
    slug?: TranslatableTextData;
    title?: TranslatableTextData;
    created_at?: string;
    document?: FileData;

    constructor(data: InformationDocumentData) {
        this.uuid = data.uuid;
        this.slug = data.slug;
        this.title = new TranslatableText(data.title ?? {});
        this.created_at = data.created_at;
        this.document = data.document;
    }

    static async all(
        config: {
            search?: string;
            pageNumber?: number;
        } = {}
    ): Promise<{ informationdocuments: InformationDocument[]; meta: PaginatedApiMeta }> {
        const response = await api.getPaginated<InformationDocumentData[]>(
            '/information-documents',
            {
                search: config.search || null,
                page: config.pageNumber,
            }
        );

        return {
            informationdocuments: response.data.map(
                (informationdocument) => new InformationDocument(informationdocument)
            ),
            meta: response.meta,
        };
    }

    static async get(slug: string): Promise<InformationDocument> {
        const response = await api.get<InformationDocument>(`/information-documents/${slug}`);
        return new InformationDocument(response.data);
    }

    async save(document: File): Promise<InformationDocument> {
        const fd = new FormData();

        fd.append('title', TranslatableText.getJson(this.title));
        fd.append('documents[]', document);

        const response = await api.post<InformationDocument>(
            '/information-documents',
            fd,
            {},
            { 'Content-Type': 'multipart/form-data' }
        );
        return new InformationDocument(response.data);
    }

    async delete(): Promise<void> {
        await api.delete(`/information-documents/${this.getSlug()}`);
    }

    getSlug(): string | undefined {
        return this.slug?.nl || this.slug?.fr;
    }
}
