
import { defineComponent, h } from '@vue/runtime-core';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';;

export default defineComponent({
    name: 'ScrollAreaWrapper',
    props: {
        /**
         * If true, the default slot of this component is wrapped in a QScrollArea, else it is wrapped in a div.
         */
        wrap: {
            type: Boolean,
            required: true,
        },
    },
    render() {
        const thumbStyle = {
            right: '4px',
            borderRadius: '5px',
            background: 'warning',
            width: '7px',
            opacity: 0.75,
        };

        const barStyle = {
            right: '2px',
            borderRadius: '9px',
            background: 'primary',
            width: '11px',
            opacity: 0.2,
        };

        if (typeof this.$slots === 'object') {
            const slots = this.$slots.default;
            if (this.wrap) {
                return h(
                    QScrollArea,
                    { class: 'q-ml-md', style: 'height: 800px', barStyle, thumbStyle },
                    {
                        default: slots,
                    }
                );
            } else {
                return h('div', null, {
                    default: slots,
                });
            }
        }
        return h('div', null, []);
    },
});
