import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-xs-8 col-sm-10" }
const _hoisted_2 = { class: "row text-body1" }
const _hoisted_3 = { class: "row text-caption justify-start" }
const _hoisted_4 = { class: "row items-center col-xs-12" }
const _hoisted_5 = { class: "row items-center col-xs-12" }
const _hoisted_6 = { class: "col-xs-4 col-sm-2 justify-end" }
const _hoisted_7 = { class: "row text-body2 items-center justify-end" }
const _hoisted_8 = { class: "row text-body2 items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_forum_create = _resolveComponent("forum-create")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_forum_create, {
      visible: _ctx.showCreateDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showCreateDialog) = $event)),
      onReload: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.loadForumPosts({
                pagination: _ctx.pagination,
            })
        ))
    }, null, 8, ["visible"]),
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.forumPosts,
      "onUpdate:objects": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.forumPosts) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      "onUpdate:loading": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.loading) = $event)),
      title: _ctx.t('Forum'),
      createTitle: _ctx.t('Create post'),
      onCreate: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCreateDialog = !_ctx.showCreateDialog)),
      onReload: _cache[8] || (_cache[8] = ($event: any) => (_ctx.loadForumPosts({ pagination: _ctx.pagination }))),
      onDetail: _cache[9] || (_cache[9] = 
            ({ slug }) =>
                _ctx.$router.push({
                    name: 'forum.details',
                    params: { post: slug },
                })
        )
    }, {
      tableOptions: _withCtx(() => [
        _createVNode(_component_q_select, {
          class: "col-xs-12 col-sm-6",
          options: _ctx.sortOptions,
          modelValue: _ctx.sort,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sort) = $event)),
          label: _ctx.t('Sort by'),
          "option-label": "name"
        }, null, 8, ["options", "modelValue", "label"])
      ]),
      row: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_translatable_content, {
              text: props.object.title,
              truncate: ""
            }, null, 8, ["text"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "person",
                class: "q-mr-xs"
              }),
              _createElementVNode("span", null, _toDisplayString(props.object.user.name), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "q-mr-xs"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.formatDatetime(props.object.created_at)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createTextVNode(_toDisplayString(props.object.views) + " ", 1),
            _createVNode(_component_q_icon, {
              name: "visibility",
              class: "q-ml-xs"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createTextVNode(_toDisplayString(props.object.num_comments) + " ", 1),
            _createVNode(_component_q_icon, {
              name: "comment",
              class: "q-ml-xs"
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle"])
  ], 64))
}