import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    medium: "",
    languageSelect: "",
    language: _ctx.language,
    "onUpdate:language": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.language) = $event)),
    visible: _ctx.showCreateDialog,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showCreateDialog) = $event)),
    title: _ctx.t('Create a new forum post'),
    "with-save": "",
    onSave: _ctx.createForumPost,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _createVNode(_component_translatable_input, {
        modelValue: _ctx.newForumPost.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newForumPost.title) = $event)),
        "is-valid": _ctx.isValidTitle,
        "onUpdate:is-valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isValidTitle) = $event)),
        language: _ctx.language,
        title: _ctx.t('Title'),
        type: "text",
        optional: ""
      }, null, 8, ["modelValue", "is-valid", "language", "title"]),
      _createVNode(_component_translatable_input, {
        modelValue: _ctx.newForumPost.contents,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newForumPost.contents) = $event)),
        "is-valid": _ctx.isValidDescription,
        "onUpdate:is-valid": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isValidDescription) = $event)),
        language: _ctx.language,
        title: _ctx.t('Forum message'),
        type: "textarea",
        optional: ""
      }, null, 8, ["modelValue", "is-valid", "language", "title"]),
      _createVNode(_component_spinner, {
        modelValue: _ctx.processingCreatingPost,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.processingCreatingPost) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["language", "visible", "title", "onSave", "errors"]))
}