import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da24eb0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row justify-center q-pb-md" }
const _hoisted_2 = { class: "row col-12 justify-between items-center q-pl-sm q-pr-sm" }
const _hoisted_3 = { class: "col-sm-6 col-xs-12 q-mb-xs q-mt-xs" }
const _hoisted_4 = {
  key: 0,
  class: "col-sm-6 col-xs-12"
}
const _hoisted_5 = { class: "col-12 row q-mt-xs q-col-gutter-md justify-between" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "row justify-center q-mt-md"
}
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_scroll_area_wrapper = _resolveComponent("scroll-area-wrapper")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.title), 1),
          (!_ctx.hideCreateButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_q_btn, {
                  color: "secondary",
                  disable: _ctx.loading,
                  label: _ctx.createTitle,
                  style: {"width":"100%"},
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('create')))
                }, null, 8, ["disable", "label"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_input, {
            class: "col-grow",
            color: "primary",
            label: _ctx.t('Search'),
            modelValue: _ctx.searchText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
            onKeydown: _withKeys(_withModifiers(_ctx.loadObjects, ["prevent"]), ["enter"]),
            onClear: _ctx.loadObjects,
            clearable: "",
            "hide-bottom-space": ""
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["label", "modelValue", "onKeydown", "onClear"]),
          _renderSlot(_ctx.$slots, "tableOptions", {}, undefined, true)
        ])
      ]),
      _createVNode(_component_spinner, {
        modelValue: _ctx.isLoading,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isLoading) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_scroll_area_wrapper, { wrap: _ctx.scrollable }, {
        default: _withCtx(() => [
          _createVNode(_component_q_table, {
            rows: _ctx.objectsList,
            columns: [{}],
            "row-key": "uuid",
            loading: _ctx.loading,
            pagination: _ctx.paginationObject,
            "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.paginationObject) = $event)),
            flat: "",
            grid: _ctx.grid,
            separator: "none",
            "binary-state-sort": "",
            "hide-bottom": "",
            "hide-header": ""
          }, _createSlots({ _: 2 }, [
            (_ctx.grid)
              ? {
                  name: "item",
                  fn: _withCtx((props) => [
                    _createElementVNode("div", {
                      onClick: ($event: any) => (_ctx.$emit('detail', { slug: _ctx.getSlugTranslation(props.row) })),
                      class: "q-pa-md col-xs-12 col-sm-6 col-md-4"
                    }, [
                      _createVNode(_component_q_card, {
                        class: "full-height",
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "item", {
                            object: props.row
                          }, undefined, true)
                        ]),
                        _: 2
                      }, 1024)
                    ], 8, _hoisted_6)
                  ])
                }
              : {
                  name: "body",
                  fn: _withCtx((props) => [
                    _createVNode(_component_q_tr, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_td, { style: {"max-width":"0vw"} }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_card_section, {
                                  horizontal: "",
                                  class: "row justify-between hoverable-element"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_card_section, {
                                      onClick: ($event: any) => (
                                            _ctx.$emit('detail', {
                                                slug: _ctx.getSlugTranslation(props.row),
                                            })
                                        ),
                                      class: "row justify-between items-center",
                                      style: {"width":"100%"}
                                    }, {
                                      default: _withCtx(() => [
                                        _renderSlot(_ctx.$slots, "row", {
                                          style: "width: 100%",
                                          object: props.row
                                        }, undefined, true)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]),
                                    (
                                            _ctx.editableRows &&
                                            (_ctx.isAdmin || props.object?.user?.uuid === _ctx.user.uuid)
                                        )
                                      ? (_openBlock(), _createBlock(_component_q_btn, {
                                          key: 0,
                                          color: "primary",
                                          icon: "edit",
                                          flat: "",
                                          ripple: false,
                                          onClick: ($event: any) => (
                                            _ctx.$emit('update', {
                                                slug: _ctx.getSlugTranslation(props.row),
                                            })
                                        )
                                        }, null, 8, ["onClick"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    (_ctx.objectsList.length === 0)
                      ? (_openBlock(), _createBlock(_component_q_tr, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_td, null, {
                              default: _withCtx(() => [
                                _createTextVNode("No data found")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                }
          ]), 1032, ["rows", "loading", "pagination", "grid"])
        ]),
        _: 3
      }, 8, ["wrap"]),
      (!_ctx.hidePaginationControls)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _withDirectives(_createVNode(_component_q_pagination, {
              modelValue: _ctx.paginationObject.page,
              "onUpdate:modelValue": [
                _cache[4] || (_cache[4] = ($event: any) => ((_ctx.paginationObject.page) = $event)),
                _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadObjects({ paginationObject: _ctx.paginationObject })))
              ],
              color: "primary",
              max: _ctx.pagesNumber,
              "max-pages": 10,
              size: "sm",
              "direction-links": ""
            }, null, 8, ["modelValue", "max"]), [
              [_vShow, _ctx.objectsList.length > 0]
            ]),
            (_ctx.objectsList.length === 0 && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.t('No data was found')), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}