
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    ref,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { selectAtLeastOne } from '@/validation/validation';
import QFile from 'quasar/src/components/file/QFile.js';;

export default defineComponent({
    name: 'FileSelector',
    props: {
        modelValue: {
            type: Array as PropType<File[] | File>,
            required: true,
        },
        maxFiles: {
            type: Number,
            required: false,
            default: 20,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Files',
        },
        images: {
            type: Boolean,
            required: false,
            default: false,
        },
        single: {
            type: Boolean,
            required: false,
            default: false,
        },
        isValid: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const files: WritableComputedRef<File[] | File> = computed({
            get: () => props.modelValue,
            set: (i: File[] | File) => {
                emit('update:modelValue', i);
            },
        });

        const fileSelectorRef = ref<QFile | null>(null);
        const validateSelector = async () => {
            await nextTick();
            if (!props.required) {
                emit('update:isValid', true);
            } else {
                emit('update:isValid', (await fileSelectorRef.value?.validate()) ?? false);
            }
        };

        onMounted(() => validateSelector());

        return {
            t,
            files,
            selectAtLeastOne,
            fileSelectorRef,
            validateSelector,
        };
    },
});
