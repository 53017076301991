import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e19d9ef0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 text-body1 announcement-title" }
const _hoisted_2 = { class: "col-12 text-caption justify-start" }
const _hoisted_3 = {
  key: 0,
  class: "row items-center col-12"
}
const _hoisted_4 = { class: "col-12 row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_announcement_details = _resolveComponent("announcement-details")!
  const _component_announcement_update = _resolveComponent("announcement-update")!
  const _component_announcement_create = _resolveComponent("announcement-create")!
  const _component_commissions_selector = _resolveComponent("commissions-selector")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_overview_page_table_layout = _resolveComponent("overview-page-table-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_announcement_details, {
      announcementSlug: _ctx.announcementSlug,
      "onUpdate:announcementSlug": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.announcementSlug) = $event)),
      visible: _ctx.showAnnouncementDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showAnnouncementDialog) = $event)),
      onReload: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadAnnouncements({ pagination: _ctx.pagination })))
    }, null, 8, ["announcementSlug", "visible"]),
    _createVNode(_component_announcement_update, {
      modelValue: _ctx.showAnnouncementUpdateDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAnnouncementUpdateDialog) = $event)),
      announcementSlug: _ctx.announcementUpdateSlug,
      onReload: _cache[4] || (_cache[4] = ($event: any) => {
            _ctx.loadAnnouncements({ pagination: _ctx.pagination });
            _ctx.announcementUpdateSlug = null;
        })
    }, null, 8, ["modelValue", "announcementSlug"]),
    _createVNode(_component_announcement_create, {
      modelValue: _ctx.showAnnouncementCreateDialog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showAnnouncementCreateDialog) = $event)),
      onCreated: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadAnnouncements({ pagination: _ctx.pagination })))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_overview_page_table_layout, {
      objects: _ctx.announcements,
      "onUpdate:objects": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.announcements) = $event)),
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pagination) = $event)),
      search: _ctx.searchFilter,
      "onUpdate:search": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.searchFilter) = $event)),
      loading: _ctx.loading,
      "onUpdate:loading": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.loading) = $event)),
      editableRows: "",
      title: _ctx.t('Announcements'),
      createTitle: _ctx.t('Create announcement'),
      onReload: _cache[12] || (_cache[12] = ($event: any) => (_ctx.loadAnnouncements({ pagination: _ctx.pagination }))),
      onCreate: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showAnnouncementCreateDialog = !_ctx.showAnnouncementCreateDialog)),
      hideCreateButton: !(_ctx.user?.is_commission_member || _ctx.user?.is_admin),
      onDetail: _cache[14] || (_cache[14] = 
            ({ slug }) =>
                _ctx.$router.push({ name: 'announcement.overview', params: { announcement: slug } })
        ),
      onUpdate: _cache[15] || (_cache[15] = 
            ({ slug }) => {
                _ctx.announcementUpdateSlug = slug;
                _ctx.showAnnouncementUpdateDialog = true;
            }
        )
    }, {
      tableOptions: _withCtx(() => [
        _createVNode(_component_commissions_selector, {
          class: "col-xs-12 col-sm-6",
          modelValue: _ctx.commissions,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.commissions) = $event)),
          required: false
        }, null, 8, ["modelValue"])
      ]),
      row: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_translatable_content, {
            text: props.object.title,
            truncate: ""
          }, null, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          (props.object.commission)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_q_icon, {
                  name: "group",
                  class: "q-mr-xs"
                }),
                _createElementVNode("span", null, _toDisplayString(props.object.commission), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_icon, {
              name: "event",
              class: "q-mr-xs"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(props.object.created_at)), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["objects", "pagination", "search", "loading", "title", "createTitle", "hideCreateButton"])
  ], 64))
}