import { AxiosRequestConfig } from 'axios';
import store from '@/store/index';
import User from '../models/User';

const setLanguage = (request: AxiosRequestConfig): AxiosRequestConfig => {
    const user: User | null = store.getters.user;
    if (user) {
        request.headers.common['Accept-Language'] = user.language || 'en';
    }
    return request;
};

export default setLanguage;
