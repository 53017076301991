
import { computed, defineComponent, Ref, ComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';

export default defineComponent({
    name: 'DateSelector',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();

        const dateModel: Ref<string | null> = computed({
            get: () => props.modelValue,
            set: (value: string | null) => emit('update:modelValue', value),
        });

        const dateDisplayText: ComputedRef<string> = computed(() => {
            return dateModel.value !== null ? `${formatDate(dateModel.value || '')}` : '';
        });

        return {
            t,
            dateModel,
            dateDisplayText,
        };
    },
});
