
import { ApiValidationError } from '@/api/core/api';
import Password from '@/api/models/Password';
import Errors from '@/components/Notifications/Errors.vue';
import { computed, defineComponent, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { required, email, strongPassword, maxLength } from '@/validation/validation';
import QInput from 'quasar/src/components/input/QInput.js';;

export default defineComponent({
    components: { Errors },
    name: 'ResetPassword',
    setup() {
        const i18n = useI18n();
        const router = useRouter();

        const setLang = (lang: string) => {
            i18n.locale.value = lang;
            resetValidation();
        };

        const errors: Ref<string[]> = ref([]);
        const loading: Ref<boolean> = ref(false);

        const emailInput: Ref<string> = ref(router.currentRoute.value.params.email as string);
        const password: Ref<string> = ref('');
        const passwordConfirmation: Ref<string> = ref('');

        const { t } = useI18n();
        const equalToPassword = () =>
            passwordConfirmation.value === password.value ||
            t('Password confirmation is not equal to new password.');

        const emailInputRef = ref<QInput | null>(null);
        const passwordRef = ref<QInput | null>(null);
        const passwordConfirmationRef = ref<QInput | null>(null);

        const resetValidation = () => {
            emailInputRef.value?.validate();
            passwordRef.value?.validate();
            passwordConfirmationRef.value?.validate();
        };

        const isValidForm = computed(() => {
            return (
                emailInputRef.value?.validate() &&
                passwordRef.value?.validate() &&
                passwordConfirmationRef.value?.validate()
            );
        });

        const resetPassword = async () => {
            if (isValidForm.value) {
                try {
                    loading.value = true;
                    await Password.reset(
                        emailInput.value,
                        password.value,
                        passwordConfirmation.value,
                        router.currentRoute.value.params.token as string
                    );
                    router.replace({ name: 'sign-in' });
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else {
                        throw e;
                    }
                } finally {
                    loading.value = false;
                }
            }
        };

        return {
            t: i18n.t,
            setLang,
            errors,
            loading,
            emailInput,
            password,
            passwordConfirmation,
            resetPassword,
            email,
            required,
            strongPassword,
            maxLength,
            equalToPassword,
            emailInputRef,
            passwordRef,
            passwordConfirmationRef,
        };
    },
});
