import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.dateDisplayText,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateDisplayText) = $event)),
    label: _ctx.label,
    readonly: "",
    disabled: "",
    "input-class": "date-input"
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "event",
        class: "cursor-pointer"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_q_popup_proxy, {
        class: "date-proxy",
        ref: "qDateProxy",
        "transition-show": "scale",
        "transition-hide": "scale"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_date, {
            modelValue: _ctx.dateModel,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateModel) = $event)),
            mask: "YYYY-MM-DD"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.clearable)
                  ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      label: _ctx.t('Clear'),
                      flat: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dateModel = null))
                    }, null, 8, ["label"])), [
                      [_directive_close_popup]
                    ])
                  : _createCommentVNode("", true),
                _createVNode(_component_q_space),
                _withDirectives(_createVNode(_component_q_btn, {
                  label: _ctx.t('Close'),
                  flat: ""
                }, null, 8, ["label"]), [
                  [_directive_close_popup]
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["modelValue", "label"]))
}