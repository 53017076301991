{
    "Authenticating": "Authentification",
    "Delete": "Supprimer",
    "Save": "Sauvegarder",
    "Are you sure you want to delete this?": "Êtes-vous sûr de vouloir le supprimer ?",
    "Cancel": "Annuler",
    "Confirm delete": "Confirmer la suppression",
    "Password forgotten": "Mot de passe oublié",
    "Reset password": "Réinitialiser le mot de passe",
    "Email": "Messagerie électronique",
    "Password reset request succesful. Check your email for the reset link.": "Demande de réinitialisation de mot de passe réussie. Vérifiez votre e-mail pour le lien de réinitialisation.",
    "Hide": "Cacher",
    "Announcements": "Annonces",
    "Documents": "Documents",
    "Forum": "Forum",
    "Photos": "Photos",
    "Commissions": "Commissions",
    "Users": "Utilisateurs",
    "Search": "Rechercher",
    "No data was found": "Aucune donnée n’a été trouvée",
    "Commission": "Commission",
    "Clear": "Clair",
    "Close": "Fermer",
    "User": "Utilisateur",
    "Villas": "Villas",
    "Villa": "Villa",
    "Owner of house(s)": "Propriétaire de la (des) maison(s)",
    "Owners": "Propriétaires de la maison",
    "Owner": "Propriétaire de la maison",
    "Home address": "Adresse du domicile",
    "Telephone number": "Numéro de téléphone",
    "Mobile phone number": "Numéro de téléphone portable",
    "Hobbies": "Loisirs",
    "Date of birth": "Date de naissance",
    "Account information": "Informations sur le compte",
    "New avatar": "Nouvel avatar",
    "Change password": "Changer le mot de passe",
    "Current password": "Mot de passe actuel",
    "New password": "Nouveau mot de passe",
    "Confirm password": "Confirmer le mot de passe",
    "Change": "Changement",
    "Personal information": "Renseignements personnels",
    "Phone number": "Numéro de téléphone",
    "Receive forum post notifications": "Recevoir des notifications de publication sur le forum",
    "Street": "Rue",
    "Number": "Nombre",
    "Postal code": "Code postal",
    "City": "Ville",
    "Create new announcement": "Créer une nouvelle annonce",
    "Title": "Titre",
    "Contents": "Contenu",
    "Upload documents (optional)": "Télécharger des documents (facultatif)",
    "Announcement": "Annonce",
    "Create announcement": "Créer une annonce",
    "Update announcement": "Annonce de mise à jour",
    "Upload additional documents (optional)": "Télécharger des documents supplémentaires (facultatif)",
    "Only members of commissions can update their own announcements.": "Seuls les membres des commissions peuvent mettre à jour leurs propres annonces.",
    "Edit a commission": "Modifier une commission",
    "Create new commission": "Créer une nouvelle commission",
    "Name": "Nom",
    "Add member": "Ajouter un membre",
    "Create commission": "Créer une commission",
    "Edit": "Éditer",
    "Create new document archive": "Créer une archive de documents",
    "Meeting date": "Date de la réunion",
    "Upload date": "Date de téléchargement",
    "Document archives": "Document archives",
    "Create archive": "Créer une archive",
    "Update document archive": "Mettre à jour l’archive des documents",
    "Create a new forum post": "Créer un nouveau message sur le forum",
    "Forum message": "Forum message",
    "Delete post": "Supprimer un message",
    "Type your comment here": "Tapez votre commentaire ici",
    "Add comment": "Ajouter un commentaire",
    "Create post": "Créer une publication",
    "Sort by": "Trier par",
    "Date (latest first)": "Date (la plus récente en premier)",
    "Date (oldest first)": "Date (la plus ancienne en premier)",
    "Views": "Affichage",
    "Comments": "Commentaires",
    "Create a new photo album": "Créer un nouvel album photo",
    "Description": "Description",
    "Photo albums": "Albums photos",
    "Create album": "Créer un album",
    "Ownersportal": "Portail des propriétaires",
    "Password": "Mot de passe",
    "Password confirmation": "Confirmation du mot de passe",
    "Reset": "Réinitialisation",
    "Sign in": "Connexion",
    "These credentials are wrong": "Ces informations d’identification sont incorrectes",
    "Admin": "Admin",
    "Same address and villas as (optional)": "Même adresse et villas que (facultatif)",
    "Create user": "Créer un utilisateur",
    "No owners yet": "Pas encore de propriétaires",
    "Logout": "Se déconnecter",
    "validation": {
        "required": "{fieldName} est requis",
        "email": "S'il vous plaît, mettez une adresse email valide",
        "maxLength": "{fieldName} ne peut pas être plus grand que {length} personnages",
        "atLeastOne": "Sélectionnez au moins une {name}",
        "strongPassword": "Les mots de passe doivent contenir au moins 8 caractères, une lettre, une majuscule et un chiffre."
    },
    "commission": "commission",
    "document": "document",
    "photo": "photo",
    "Please fill in both translations.": "Veuillez remplir les deux traductions.",
    "Password confirmation is not equal to new password.": "La confirmation du mot de passe n'est pas égale au nouveau mot de passe.",
    "Please choose an image first": "Veuillez d'abord choisir une image",
    "Fill in a title for at least one language.": "Remplissez un titre pour au moins une langue.",
    "Fill in a comment for for at least one language.": "Remplissez un commentaire pour au moins une langue.",
    "Board": "Le conseil",
    "Information": "Informations",
    "Information documents": "Documents d'information",
    "Upload document": "Télécharger le document",
    "Document": "Document",
    "Upload new document": "Télécharger un nouveau document"
}
