
import DocumentArchive, { DocumentArchiveData } from '@/api/models/DocumentArchive';
import { FileData } from '@/api/models/File';
import { defineComponent, PropType, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DeleteConfirmDialog from '@/components/Interaction/DeleteConfirmDialog.vue';
import Spinner from '@/components/Util/Spinner.vue';

export default defineComponent({
    name: 'Documents',
    components: { DeleteConfirmDialog, Spinner },
    emits: ['deleted'],
    props: {
        documentArchive: {
            type: Object as PropType<DocumentArchiveData>,
            required: true,
        },
        downloadOption: {
            type: Boolean,
            default: true,
        },
        deleteOption: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showDeleteConfirmDialog = ref(false);
        const isProcessing = ref(false);
        const documentToDelete: Ref<FileData | null> = ref(null);

        const downloadDocument = (url: string) => {
            if (props.downloadOption) {
                window.open(url, '_blank');
            }
        };

        const deleteDocument = async () => {
            if (props.deleteOption && documentToDelete.value != null) {
                var documentArchive = new DocumentArchive(props.documentArchive);
                isProcessing.value = true;
                documentArchive = await documentArchive.deleteDocument(documentToDelete.value);
                isProcessing.value = false;
                emit('deleted', { documentArchive });
            }
        };

        return {
            t,
            showDeleteConfirmDialog,
            documentToDelete,
            isProcessing,
            downloadDocument,
            deleteDocument,
        };
    },
});
