import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row items-center q-mt-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_language_toggle = _resolveComponent("language-toggle")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_user_selector = _resolveComponent("user-selector")!
  const _component_villas_selector = _resolveComponent("villas-selector")!
  const _component_crud_dialog = _resolveComponent("crud-dialog")!

  return (_openBlock(), _createBlock(_component_crud_dialog, {
    small: "",
    visible: _ctx.showUserCreateDialog,
    "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showUserCreateDialog) = $event)),
    title: _ctx.t('Create user'),
    errors: _ctx.errors,
    "is-processing": _ctx.loading,
    "with-save": "",
    onSave: _ctx.saveUser,
    onClose: _ctx.closeUserCreateDialog
  }, {
    default: _withCtx(() => [
      (_ctx.showSkeleton)
        ? (_openBlock(), _createBlock(_component_q_skeleton, {
            key: 0,
            type: "textarea",
            height: "500px"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_input, {
              ref: "usernameRef",
              modelValue: _ctx.user.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event)),
              label: _ctx.t('Name'),
              rules: [_ctx.required('Name'), _ctx.maxLength('Name', 255)],
              class: "col-xs-12"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "person" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_input, {
              ref: "userEmailRef",
              modelValue: _ctx.user.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event)),
              class: "col-xs-12",
              label: _ctx.t('Email'),
              rules: [_ctx.required('Email'), _ctx.email]
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "email" })
              ]),
              _: 1
            }, 8, ["modelValue", "label", "rules"]),
            _createVNode(_component_q_toggle, {
              class: "col-xs-4 q-mt-md",
              modelValue: _ctx.user.is_admin,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.is_admin) = $event)),
              label: _ctx.t('Admin')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_language_toggle, {
              modelValue: _ctx.user.language,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.language) = $event)),
              class: "col-xs-8 q-mt-md"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_q_separator, { class: "col-xs-12 q-mt-md q-mb-md" }),
            _createVNode(_component_user_selector, {
              class: "col-xs-12",
              label: _ctx.t('Same villas as (optional)'),
              user: _ctx.sameUser,
              "onUpdate:user": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sameUser) = $event))
            }, null, 8, ["label", "user"]),
            _createVNode(_component_villas_selector, {
              class: "col-xs-12",
              modelValue: _ctx.user.villas,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.villas) = $event))
            }, null, 8, ["modelValue"])
          ]))
    ]),
    _: 1
  }, 8, ["visible", "title", "errors", "is-processing", "onSave", "onClose"]))
}