import api from '../core/api';
import User, { UserData } from './User';

export interface CommissionData {
    uuid?: string;
    name?: string;
    is_board?: boolean;
    members?: UserData[];
}

export type Member = { user: User | null; title: string | null };

export default class Commission implements CommissionData {
    uuid?: string;
    name?: string;
    is_board?: boolean;
    users?: UserData[];

    constructor(data: CommissionData) {
        this.uuid = data.uuid;
        this.name = data.name;
        this.is_board = data.is_board;
        this.users = data.members;
    }

    static async all(): Promise<Commission[]> {
        const response = await api.getPaginated<CommissionData[]>('/commissions');
        return response.data.map((commission) => new Commission(commission));
    }

    private static mapMembersToArray(members: Member[]) {
        return members
            .filter((member) => member.user !== null)
            .map((member) => {
                return [member.user?.uuid, member.title];
            });
    }

    static async create(name: string, members: Member[], isBoard = false): Promise<Commission> {
        const payload = {
            name: name,
            is_board: isBoard,
            members: this.mapMembersToArray(members),
        };

        const response = await api.post<CommissionData>('/commissions', payload);
        return new Commission(response.data);
    }

    async save(members: Member[]): Promise<Commission> {
        const payload = {
            name: this.name,
            is_board: this.is_board,
            members: Commission.mapMembersToArray(members),
        };

        const response = await api.patch<Commission>(`/commissions/${this.uuid}`, payload);
        return new Commission(response.data);
    }

    async delete(): Promise<void> {
        await api.delete<void>(`/commissions/${this.uuid}`);
    }
}
