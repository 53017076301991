import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    options: _ctx.villas,
    modelValue: _ctx.villasModel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.villasModel) = $event)),
    multiple: "",
    label: _ctx.t('Villas'),
    "option-label": "number",
    clearable: "",
    loading: _ctx.loadingVillas,
    "use-chips": "",
    "options-dense": ""
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "house" })
    ]),
    _: 1
  }, 8, ["options", "modelValue", "label", "loading"]))
}