
import { ApiAuthorizationError, ApiValidationError } from '@/api/core/api';
import Announcement from '@/api/models/Announcement';
import Commission from '@/api/models/Commission';
import { computed, defineComponent, Ref, ref, watch, WritableComputedRef } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import CommissionsSelector from '@/components/Selectors/CommissionsSelector.vue';
import CrudDialog from '../../components/Interaction/CrudDialog.vue';
import FileSelector from '../../components/Interaction/FileSelector.vue';
import TranslatableInput from '../../components/Interaction/TranslatableInput.vue';
import { missingTranslationNotification } from '@/composables/notifications';
import { useStore } from 'vuex';
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
    components: { CrudDialog, CommissionsSelector, FileSelector, TranslatableInput },
    name: 'AnnouncementCreate',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:modelValue', 'created'],
    setup(props, { emit }) {
        const store = useStore();
        const user = computed(() => store.getters.user);

        const errors: Ref<string[]> = ref([]);
        const isProcessing = ref(false);
        const { t } = useI18n();

        const newAnnouncement: Ref<Announcement> = ref(new Announcement({}));
        const selectedCommission: Ref<Commission | null> = ref(null);
        const documents: Ref<File[]> = ref([]);
        const language: Ref<string> = ref('nl');

        const visible: WritableComputedRef<boolean> = computed({
            get: () => props.modelValue,
            set: (i: boolean) => {
                emit('update:modelValue', i);
            },
        });

        watch(selectedCommission, (updatedCommission: Commission | null) => {
            newAnnouncement.value.commission = updatedCommission || undefined;
        });

        const validTitle = ref(false);
        const validContents = ref(false);
        const validCommissionSelector = ref(false);
        const validForm = computed(
            () => validTitle.value && validContents.value && validCommissionSelector.value
        );

        const $q = useQuasar();
        const saveAnnouncement = async () => {
            if (validForm.value) {
                isProcessing.value = true;
                try {
                    await newAnnouncement.value.save(documents.value);
                    visible.value = false;
                    emit('created');
                    clearInput();
                } catch (e) {
                    if (e instanceof ApiValidationError) {
                        errors.value = e.getErrors();
                    } else if (e instanceof ApiAuthorizationError) {
                        errors.value = [t('Only members of commissions can create announcements.')];
                    } else {
                        throw e;
                    }
                } finally {
                    isProcessing.value = false;
                }
            } else {
                if (!validTitle.value || !validContents.value) {
                    $q.notify(missingTranslationNotification);
                }
            }
        };

        const clearInput = () => {
            newAnnouncement.value = new Announcement({});
            documents.value = [];
            selectedCommission.value = null;
        };

        return {
            visible,
            errors,
            isProcessing,
            t,
            user,
            newAnnouncement,
            selectedCommission,
            documents,
            language,
            saveAnnouncement,
            validTitle,
            validContents,
            validCommissionSelector,
        };
    },
});
