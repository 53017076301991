
import Announcement from '@/api/models/Announcement';
import CrudDialog from '@/components/Interaction/CrudDialog.vue';
import Documents from '@/components/Documents.vue';
import {
    defineComponent,
    onBeforeMount,
    ref,
    Ref,
    computed,
    watch,
    WritableComputedRef,
} from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { formatDatetime } from '@/util/formatters';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';

export default defineComponent({
    name: 'AnnouncementDetails',
    components: { CrudDialog, Documents, TranslatableContent },
    emits: ['reload', 'update:visible'],
    props: {
        /**
         * If the dialog is opened.
         */
        visible: {
            type: Boolean,
            default: false,
        },
        /**
         * Slug of the announcement.
         */
        announcementSlug: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const showAnnouncementDialog: WritableComputedRef<boolean> = computed({
            get: () => props.visible,
            set: (v: boolean) => {
                emit('update:visible', v);
            },
        });

        const announcement: Ref<Announcement | null> = ref(null);
        const loading: Ref<boolean> = ref(false);

        const loadAnnouncement = async () => {
            if (props.announcementSlug) {
                announcement.value = null;
                announcement.value = await Announcement.get(props.announcementSlug as string);
            }
        };

        onBeforeMount(async () => await loadAnnouncement());

        watch(
            () => props.announcementSlug,
            async () => await loadAnnouncement()
        );

        return {
            t,
            loading,
            showAnnouncementDialog,
            announcement,
            formatDatetime,
        };
    },
});
