
import { defineComponent, PropType, computed, ref } from '@vue/runtime-core';
import { formatDatetime } from '@/util/formatters';
import ForumComment from '@/api/models/ForumComment';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import DeleteConfirmDialog from '@/components/Interaction/DeleteConfirmDialog.vue';
import TranslatableContent from '@/components/Functional/TranslatableContent.vue';

export default defineComponent({
    components: { DeleteConfirmDialog, TranslatableContent },
    name: 'ForumCommentDetails',
    emits: ['deleteComment'],
    props: {
        comment: {
            type: Object as PropType<ForumComment>,
            required: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();
        const user = computed(() => store.getters.user);
        const isAdmin = computed(() => store.getters.isAdmin);
        const showDeleteForumPostConfirmDialog = ref(false);

        const deleteComment = async () => {
            emit('deleteComment', { commentUuid: props.comment.uuid });
        };

        return {
            t,
            user,
            isAdmin,
            showDeleteForumPostConfirmDialog,
            deleteComment,
            formatDatetime,
        };
    },
});
