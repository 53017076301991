
import { defineComponent, computed, ref, watch, WritableComputedRef } from '@vue/runtime-core';
import User from '@/api/models/User';
import LanguageSelector from '@/components/Selectors/LanguageSelector.vue';
import { MutationTypes } from '@/store/mutations';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AppMenu',
    components: { LanguageSelector },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { t } = useI18n();

        const user = computed(() => store.getters.user);
        const isAdmin = computed(() => store.getters.isAdmin);
        const language = ref(store.getters.displayLanguage);
        const isMenuOpen: WritableComputedRef<boolean> = computed({
            get: () => store.getters.isMenuOpen,
            set: (val: boolean) => store.commit(MutationTypes.setMenu, val),
        });

        watch(
            () => language.value,
            () => store.commit(MutationTypes.setDisplayLanguage, language.value)
        );

        watch(
            () => store.getters.displayLanguage,
            () => (language.value = store.getters.displayLanguage)
        );

        const signOut = () => {
            User.signOut();
            store.commit(MutationTypes.setUser, null);
            router.replace({ name: 'sign-in' });
        };

        const toggleMenu = () => {
            store.commit(MutationTypes.toggleMenu);
        };

        const showLeftDrawer = ref(true);

        const miniState = ref(true);

        const menuList = computed(() => [
            {
                icon: 'message',
                link: 'announcements',
                label: t('Announcements'),
                to: 'announcement.overview',
            },
            {
                icon: 'article',
                link: 'document-archives',
                label: t('Documents'),
                to: 'documentarchive.overview',
            },
            {
                icon: 'info',
                link: 'information-documents',
                label: t('Information'),
                to: 'information-documents.overview',
            },
            {
                icon: 'forum',
                link: 'forum',
                label: t('Forum'),
                to: 'forum.overview',
            },
            {
                icon: 'photo_camera',
                link: 'photo-albums',
                label: t('Photos'),
                to: 'photoalbum.overview',
            },
            {
                icon: 'countertops',
                link: 'commissions',
                label: t('Commissions'),
                to: 'commissions',
            },
            {
                icon: 'house',
                link: 'villas',
                label: t('Villas'),
                to: 'villa.overview',
            },
        ]);

        return {
            t,
            isMenuOpen,
            toggleMenu,
            signOut,
            user,
            language,
            isAdmin,
            showLeftDrawer,
            miniState,
            menuList,
        };
    },
});
