import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!

  return (_openBlock(), _createBlock(_component_q_btn_toggle, {
    modelValue: _ctx.language,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.language) = $event)),
    spread: "",
    options: [
            { label: 'Nederlands', value: 'nl' },
            { label: 'Français', value: 'fr' },
        ],
    type: "radio"
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "language" })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}