
import { defineComponent, WritableComputedRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'LanguageSelector',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        lightBorder: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const language: WritableComputedRef<string> = computed({
            get: () => props.modelValue,
            set: (v: string) => {
                emit('update:modelValue', v);
            },
        });

        const nl = computed(() => language.value === 'nl');
        const fr = computed(() => language.value === 'fr');

        return { t, nl, fr, language };
    },
});
