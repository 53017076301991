import './styles/quasar.scss';
import lang from 'quasar/lang/nl.js';
import '@quasar/extras/material-icons/material-icons.css';
import Notify from 'quasar/src/plugins/Notify.js';;

export default {
    config: {},
    plugins: { Notify },
    lang: lang,
};
