
import {
    computed,
    ComputedRef,
    defineComponent,
    WritableComputedRef,
    PropType,
} from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/util/formatters';
import { QPaginationMeta } from '@/util/pagination';
import Spinner from '@/components/Util/Spinner.vue';
import ScrollAreaWrapper from '@/components/Functional/ScrollAreaWrapper.vue';

export default defineComponent({
    components: { Spinner, ScrollAreaWrapper },
    name: 'OverviewPageTableLayout',
    emits: [
        'update:objects',
        'update:pagination',
        'update:search',
        'update:loading',
        'reload',
        'update',
        'create',
        'detail',
    ],
    props: {
        /**
         * List of objectsList to display in the table.
         */
        objects: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object as PropType<QPaginationMeta>,
            required: true,
        },
        search: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        createTitle: {
            type: String,
            required: false,
            default: '',
        },
        editableRows: {
            type: Boolean,
            default: false,
        },
        grid: {
            type: Boolean,
            default: false,
        },
        hideCreateButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        hidePaginationControls: {
            type: Boolean,
            required: false,
            default: false,
        },
        scrollable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const store = useStore();

        const language = computed(() => store.getters.displayLanguage);
        const isAdmin = computed(() => store.getters.isAdmin);
        const user = computed(() => store.getters.user);

        const getSlugTranslation = (object: any): any => {
            if ('slug' in object) {
                if (typeof object.slug === 'object') {
                    return language.value === 'nl'
                        ? object.slug.nl || object.slug.fr
                        : object.slug.fr || object.slug.nl;
                } else {
                    return object.slug;
                }
            } else {
                return object.number;
            }
        };

        const objectsList: WritableComputedRef<Array<unknown>> = computed({
            get: () => props.objects,
            set: (i: Array<unknown>) => {
                emit('update:objects', i);
            },
        });

        const paginationObject: WritableComputedRef<QPaginationMeta> = computed({
            get: () => props.pagination,
            set: (i: QPaginationMeta) => {
                emit('update:pagination', i);
            },
        });

        const searchText: WritableComputedRef<string | null> = computed({
            get: () => props.search,
            set: (i: string | null) => {
                emit('update:search', i);
            },
        });

        const isLoading: WritableComputedRef<boolean> = computed({
            get: () => props.loading,
            set: (i: boolean) => {
                emit('update:loading', i);
            },
        });

        const pagesNumber: ComputedRef<number> = computed(() =>
            Math.ceil(paginationObject.value.rowsNumber / paginationObject.value.rowsPerPage)
        );

        const loadObjects = () => emit('reload');

        return {
            t,
            objectsList,
            isLoading,
            loadObjects,
            paginationObject,
            pagesNumber,
            formatDate,
            searchText,
            getSlugTranslation,
            emit,
            isAdmin,
            user,
        };
    },
});
