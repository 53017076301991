import i18n from '@/i18n';

const { t } = i18n.global;

const commentForOneLanguageIsRequiredNotification = {
    color: 'negative',
    message: t('Fill in a comment for for at least one language.'),
    position: 'top' as const,
    timeout: 4000,
    icon: 'warning',
};

const titleForOneLanguageIsRequiredNotification = {
    color: 'negative',
    message: t('Fill in a title for at least one language.'),
    position: 'top' as const,
    timeout: 4000,
    icon: 'warning',
};

const missingTranslationNotification = {
    color: 'negative',
    message: t('Please fill in both translations.'),
    position: 'top' as const,
    timeout: 4000,
    icon: 'warning',
};

export {
    commentForOneLanguageIsRequiredNotification,
    titleForOneLanguageIsRequiredNotification,
    missingTranslationNotification,
};
